import React, { useState, useEffect } from 'react';

const PasswordInput = ({ onPasswordChange, onConfirmPasswordChange, passwordError }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');

  useEffect(() => {
    onPasswordChange(password);
    onConfirmPasswordChange(confirmPassword);
  }, [password, confirmPassword, onPasswordChange, onConfirmPasswordChange]); // 의존성 배열에 추가

  return (
    <>
      <div className="signup-inputWrapper">
        <label htmlFor="password" className="signup-label">비밀번호</label>
        <input
          type="password"
          id="password"
          placeholder="비밀번호를 입력하세요"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          required
          className="signup-input"
        />
      </div>
      <div className="signup-inputWrapper">
        <label htmlFor="confirmPassword" className="signup-label">비밀번호 확인</label>
        <input
          type="password"
          id="confirmPassword"
          placeholder="동일 비밀번호를 입력하세요"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          required
          className="signup-input"
        />
        {passwordError && <p className="signup-checkMessage">{passwordError}</p>}
      </div>
    </>
  );
};

export default PasswordInput;
