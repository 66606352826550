import React from 'react';

const LLMDetailedInfo = () => {
    const titleContainerStyle = {
        width: '100%',
        maxWidth: '1200px',
        marginBottom: '1rem',
        textAlign: 'left',
    };

    const titleStyle = {
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#333',
    };

    const containerStyle = {
        width: '100%', // 부모 요소의 100% 너비를 사용
        maxWidth: '1200px',
        padding: '2rem',  // Padding을 더 넉넉하게
        backgroundColor: '#ffffff', // 배경을 흰색으로 변경
        borderRadius: '12px', // 약간 더 둥글게
        marginBottom: '2rem',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // 부드러운 그림자 추가
        border: '1px solid #e0e0e0', // 경계선 추가
        boxSizing: 'border-box', // 패딩을 포함하여 크기를 계산
    };

    const contentStyle = {
        fontSize: '1.1rem', // 약간 큰 글꼴 크기
        color: '#444', // 글꼴 색상을 좀 더 연하게
        lineHeight: '1.6', // 줄 간격을 넉넉하게
        textAlign: 'left',
    };

    return (
        <>
            <div style={titleContainerStyle}>
                <h2 style={titleStyle}>GPT가 알려주는 여행</h2>
            </div>
            <div style={containerStyle}>
                <p style={contentStyle}>
                    LLM을 통해 생성된 여행 설명이 여기에 표시됩니다. 여행 경로, 추천 활동, 현지 문화 정보 등을 포함합니다.
                </p>
            </div>
        </>
    );
};

export default LLMDetailedInfo;
