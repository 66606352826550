import React from 'react';

const Product = () => (
  <div className="content">
    <h1>여행상품</h1>
    {/* 여행상품 페이지의 내용 */}
  </div>
);

export default Product;
