import React from 'react';
import styled from 'styled-components';

const CouponsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const Coupon = styled.div`
  width: 80%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  margin: 0.5rem 0;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
`;

const Coupons = () => {
    return (
        <CouponsWrapper>
            <Coupon>쿠폰 1</Coupon>
            <Coupon>쿠폰 2</Coupon>
        </CouponsWrapper>
    );
};

export default Coupons;