import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { createUserWithEmailAndPassword, linkWithCredential } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import Header from '../common/Header';
import Modal from 'react-modal';
import PhoneInput from './SignUp/PhoneInput';
import EmailInput from './SignUp/EmailInput';
import BirthdayInput from './SignUp/BirthdayInput';
import PasswordInput from './SignUp/PasswordInput';
import NameInput from './SignUp/NameInput';
import './SignUpPage.css';

Modal.setAppElement('#root');

const SignUpPage = () => {
  const [fullEmail, setFullEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [name, setName] = useState('');
  const [nameCheckMessage, setNameCheckMessage] = useState('');
  const [phone, setPhone] = useState(''); // 전화번호
  const [phoneCredential, setPhoneCredential] = useState(null); // 인증된 PhoneAuthCredential 저장
  const [birthday, setBirthday] = useState('');
  const [emailCheckMessage, setEmailCheckMessage] = useState('');
  const [phoneCheckMessage, setPhoneCheckMessage] = useState('');
  const [error, setError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [termsChecked, setTermsChecked] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (password !== confirmPassword) {
      setPasswordError('비밀번호가 일치하지 않습니다.');
    } else {
      setPasswordError('');
    }
  }, [password, confirmPassword]);

  const handlePhoneChange = (newPhone) => {
    setPhone(newPhone);
  };

  const handleEmailChange = (newEmail) => {
    setFullEmail(newEmail);
  };

  const handleBirthdayChange = useCallback((newBirthday) => {
    setBirthday(newBirthday);
  }, []);

  const handlePasswordChange = useCallback((newPassword) => {
    setPassword(newPassword);
  }, []);

  const handleConfirmPasswordChange = useCallback((newConfirmPassword) => {
    setConfirmPassword(newConfirmPassword);
  }, []);

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    // 비밀번호 확인
    if (password !== confirmPassword) {
      setError('비밀번호가 일치하지 않습니다.');
      return;
    }

    // 이메일과 휴대전화 중복 체크 확인
    if (!emailCheckMessage.includes('사용 가능한') || !phoneCheckMessage.includes('사용 가능한')) {
      setError('이메일과 휴대전화 번호의 중복 체크를 확인해주세요.');
      return;
    }

    // 이용약관 동의 여부 확인
    if (!termsChecked) {
      setError('이용약관에 동의해주세요.');
      return;
    }

    // 휴대폰 인증이 완료되지 않았을 경우 처리
    if (!phoneCredential) {
      setError('휴대폰 인증을 완료해주세요.');
      return;
    }

    try {
      // 1. 이메일/비밀번호로 Firebase Authentication에 계정 생성
      const userCredential = await createUserWithEmailAndPassword(auth, fullEmail, password);
      const user = userCredential.user;

      if (!user) {
        setError('사용자 인증에 문제가 발생했습니다. 다시 시도해주세요.');
        return;
      }

      // 2. 생성된 계정에 전화번호 인증 정보 연결
      try {
        await linkWithCredential(user, phoneCredential);
      } catch (linkError) {
        console.error('전화번호 연결 중 오류 발생:', linkError);
        setError('전화번호 인증 중 오류가 발생했습니다.');
        return;
      }

      // 3. Firestore에 사용자 정보 저장
      const currentDate = new Date();
      await addDoc(collection(db, 'users'), {
        uid: user.uid,
        email: fullEmail,
        name,
        birthday,
        phone,
        createdAt: currentDate,
      });

      // 4. 회원가입 완료 페이지로 이동
      navigate('/signup-complete');
    } catch (error) {
      if (error.code === 'auth/email-already-in-use') {
        setError('이미 사용 중인 이메일입니다.');
      } else {
        console.error(error);
        setError('회원가입 중 오류가 발생했습니다.');
      }
    }
  };


  return (
    <>
      <Header />
      <div className="signup-pageWrapper">
        <div className="signup-formWrapper">
          <h2 className="signup-title">회원가입</h2>
          <form onSubmit={handleSignUp}>
            <NameInput
              name={name}
              setName={setName}
              setNameCheckMessage={setNameCheckMessage}
            />
            <EmailInput
              onEmailChange={handleEmailChange}
              emailCheckMessage={emailCheckMessage}
              setEmailCheckMessage={setEmailCheckMessage}
            />
            <PasswordInput
              onPasswordChange={handlePasswordChange}
              onConfirmPasswordChange={handleConfirmPasswordChange}
              passwordError={passwordError}
            />
            <BirthdayInput onBirthdayChange={handleBirthdayChange} />
            <PhoneInput
              onPhoneChange={handlePhoneChange}
              phoneCheckMessage={phoneCheckMessage}
              setPhoneCheckMessage={setPhoneCheckMessage}
              setPhoneCredential={setPhoneCredential} // 인증된 전화번호 Credential을 설정
              context="SignUpPage"
            />
            <div className="signup-termsWrapper">
              <label htmlFor="terms" className="signup-termsLabel">
                <span className="signup-termsLink" onClick={() => setModalIsOpen(true)}>이용약관</span>에 동의합니다.
              </label>
              <input
                type="checkbox"
                id="terms"
                checked={termsChecked}
                onChange={(e) => setTermsChecked(e.target.checked)}
                className="signup-checkbox"
              />
            </div>
            {error && <p className="signup-error">{error}</p>}
            <div className="signup-buttonWrapper">
              <button type="submit" className="signup-button signup-buttonPrimary">회원가입하기</button>
            </div>
          </form>
        </div>
      </div>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={() => setModalIsOpen(false)}
        contentLabel="Terms and Conditions"
        className="Modal"
        overlayClassName="Overlay"
      >
        <h2>이용약관</h2>
        <p>여기에 이용약관 내용을 입력하세요...</p>
        <button onClick={() => setModalIsOpen(false)}>닫기</button>
      </Modal>
    </>
  );
};

export default SignUpPage;
