import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import './FirstResult.css';

const loadKakaoSDK = () => {
  return new Promise((resolve, reject) => {
    if (!window.Kakao) {
      const script = document.createElement('script');
      script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
      script.async = true;
      script.onload = () => {
        const kakaoAppKey = process.env.REACT_APP_KAKAO_APP_KEY;
        if (kakaoAppKey) {
          window.Kakao.init(kakaoAppKey);
          resolve(window.Kakao);
        } else {
          reject('Kakao App Key is missing!');
        }
      };
      script.onerror = () => reject('Failed to load Kakao SDK');
      document.head.appendChild(script);
    } else {
      resolve(window.Kakao);
    }
  });
};

const FirstResult = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // fromChallengeRoulette 또는 fromRegionRoulette을 통해 어떤 룰렛에서 왔는지 확인
  const fromChallengeRoulette = location.state?.fromChallengeRoulette || false;
  const fromRegionRoulette = location.state?.fromRegionRoulette || false;
  const selectedActivity = location.state?.selectedActivityName || null;
  const selectedRegion = location.state?.selectedRegion || null;
  const selectedRegionId = location.state?.selectedRegionId || null;
  const selectedTheme = location.state?.selectedTheme || null;
  const startDate = location.state?.startDate || null;
  const endDate = location.state?.endDate || null;
  const numAdults = location.state?.numAdults || 0;
  const numChildren = location.state?.numChildren || 0;
  const docId = location.state.docId;

  useEffect(() => {
    loadKakaoSDK().then((Kakao) => {
    }).catch(error => console.error(error));
  }, []);

  const handleShareKakao = () => {
    if (window.Kakao && window.Kakao.Link) {
      window.Kakao.Link.sendDefault({
        objectType: 'feed',
        content: {
          title: '랜덤투어 결과',
          description: `랜덤투어를 통해 나온 결과입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}`,
          imageUrl: 'https://via.placeholder.com/600x300', // 대체 이미지 URL
          link: {
            mobileWebUrl: 'http://125.131.139.172:3000/',
            webUrl: 'http://125.131.139.172:3000/',
          },
        },
        buttons: [
          {
            title: '결과 보기',
            link: {
              mobileWebUrl: 'http://125.131.139.172:3000/',
              webUrl: 'http://125.131.139.172:3000/',
            },
          },
        ],
      });
    } else {
      alert('Kakao SDK가 초기화되지 않았거나, Kakao.Link 객체가 준비되지 않았습니다.');
    }
  };

  const InfoBox = ({ startDate, endDate, numAdults, numChildren }) => {
    return (
      <div className="infoBoxContainer">
        <div className="infoItem">
          <strong>여행 시작일:</strong> {new Date(startDate).toLocaleDateString()}
        </div>
        <div className="infoItem">
          <strong>여행 종료일:</strong> {new Date(endDate).toLocaleDateString()}
        </div>
        <div className="infoItem">
          <strong>성인 수:</strong> {numAdults}명
        </div>
        <div className="infoItem">
          <strong>어린이 수:</strong> {numChildren}명
        </div>
      </div>
    );
  };

  const handleShareTwitter = () => {
    const text = `랜덤투어를 통해 나온 결과입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}`;
    const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent('http://125.131.139.172:3000/')}`;
    window.open(url, '_blank');
  };

  const handleShareInstagram = () => {
    const url = `https://www.instagram.com/?url=${encodeURIComponent('http://125.131.139.172:3000/')}`;
    window.open(url, '_blank');
  };

  const handleShare = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  // 숙소 정하기 또는 챌린지 정하기 버튼 동작 변경
  const handleNextStep = () => {
    if (fromChallengeRoulette && fromRegionRoulette) {
      navigate('/search-accommodations', {
        state: {
          docId,
          selectedTheme,
          selectedActivity,
          selectedRegion,
          selectedRegionId,
          startDate,
          endDate,
          numAdults,
          numChildren,
        },
      });
    } else if (fromRegionRoulette) {
      navigate('/challenge', {
        state: {
          docId,
          fromRegionRoulette,
          selectedRegion: selectedRegion,
          selectedRegionId: selectedRegionId,
          startDate,
          endDate,
          numAdults,
          numChildren,
        },
      });
    } else if (fromChallengeRoulette) {
      navigate('/region', {
        state: {
          docId,
          fromChallengeRoulette,
          selectedActivityName: selectedActivity,
          selectedTheme: selectedTheme,
          startDate,
          endDate,
          numAdults,
          numChildren,
        },
      });
    }
  };

  const handleChangeActivity = () => {
    navigate('/challenge', {
      state: {
        docId,
        fromRegionRoulette,
        selectedRegion: selectedRegion,
        selectedRegionId: selectedRegionId,
        startDate,
        endDate,
        numAdults,
        numChildren,
      }
    });
  };

  const handleChangeRegion = () => {
    navigate('/region', {
      state: {
        docId,
        fromChallengeRoulette,
        selectedActivityName: selectedActivity,
        selectedTheme: selectedTheme,
        startDate,
        endDate,
        numAdults,
        numChildren,
      }
    });
  };

  return (
    <>
      <Header pageTitle="룰렛 결과" />
      <div className="result-container">
        <h1 className="main-title">이렇게 떠나보려고 합니다</h1>
        <InfoBox
          startDate={startDate}
          endDate={endDate}
          numAdults={numAdults}
          numChildren={numChildren}
        />

        {/* 장소와 활동이 모두 선택되었을 때 모두 보여줌 */}
        {fromRegionRoulette && (
          <div className="section">
            <div className="section-left">
              <h2>가는 곳</h2>
              <p>{selectedRegion}</p>
              <button className="changeButton" onClick={handleChangeRegion}>
                다시 정하기
              </button>
            </div>
            <div className="section-right">
              <img src="https://via.placeholder.com/150" alt="Region" />
            </div>
          </div>
        )}

        {fromChallengeRoulette && (
          <div className="section">
            <div className="section-left">
              <h2>챌린지</h2>
              <p>{selectedActivity}</p>
              <button className="changeButton" onClick={handleChangeActivity}>
                다시 정하기
              </button>
            </div>
            <div className="section-right">
              <img src="https://via.placeholder.com/150" alt="Activity" />
            </div>
          </div>
        )}

        {/* 하단 버튼 섹션 */}
        <div className="buttonWrapper">
          <button className="shareButton" onClick={handleShare}>일행과 공유하기</button>
          <button className="nextButton" onClick={handleNextStep}>
            {(fromChallengeRoulette && fromRegionRoulette) ? '숙소 정하기' : (fromChallengeRoulette ? '장소 정하기' : '챌린지 정하기')}
          </button>
        </div>
      </div>

      {isModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content">
            <div className="modal-header">
              <h2>일행과 공유하기</h2>
              <button className="close-modal" onClick={handleCloseModal}>X</button>
            </div>
            <div className="modal-body">
              <img src="assets/kakao-logo.png" alt="Kakao" onClick={handleShareKakao} />
              <img src="assets/instagram-logo.svg" alt="Instagram" onClick={handleShareInstagram} />
              <img src="assets/x.svg" alt="Twitter" onClick={handleShareTwitter} />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default FirstResult;
