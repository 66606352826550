import React, { useState, useEffect, useRef } from 'react';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { db, storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import styled from 'styled-components';
import Modal from './Modal'; // 공통 모달 컴포넌트 사용

const ProfileWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.5rem;
  border-bottom: 1px solid #ddd;
  width: 100%;
`;

const ProfileDetails = styled.div`
  display: flex;
  align-items: center;
`;

const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 50%;
  margin-right: 1rem;
  cursor: pointer;
`;

const ProfileName = styled.h2`
  margin: 0;
  font-size: 1.5rem;
`;

const FileInput = styled.input`
  display: none;
`;

const MoreButton = styled.button`
  margin-right: 1rem;
  border: #007bff 1px solid;
  border-radius: 5px;
  background: none;
  cursor: pointer;
  font-size: 1rem;
  color: #007bff;
  &:hover {
    opacity: 0.8;
  }
`;

const EditButton = styled(MoreButton)`
  margin-left: auto;
  margin-right: 0;
`;

const ModalProfileDetails = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

const ModalProfileImage = styled.img`
  width: 100px;
  height: 100px;
  border-radius: 50%;
  margin-right: 1rem;
`;

const ModalProfileName = styled.h2`
  margin: 0;
  font-size: 1.2rem;
  text-align: left;
`;

const UserInfo = styled.div`
  background-color: rgba(97, 120, 138, 0.2);
  padding: 1rem;
  border-radius: 8px;
  text-align: left;
`;

const UserInfoItem = styled.div`
  margin-bottom: 0.5rem;
  color: #333;
`;

const ProfileInfo = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const [docId, setDocId] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (!querySnapshot.empty) {
          const userDoc = querySnapshot.docs[0];
          setDocId(userDoc.id);
          setUserData(userDoc.data());
        }
      }
    };
    fetchUserData();
  }, [user]);

  const handleImageClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleImageChange = async (e) => {
    if (e.target.files[0] && user && docId) {
      const file = e.target.files[0];
      const imageRef = ref(storage, `profilePictures/${user.uid}`);
      const uploadTask = uploadBytesResumable(imageRef, file);

      uploadTask.on('state_changed',
        () => {},
        (error) => {
          console.error('Upload failed:', error);
        },
        async () => {
          const downloadURL = await getDownloadURL(uploadTask.snapshot.ref);
          const userDocRef = doc(db, 'users', docId);
          await updateDoc(userDocRef, { profilePicture: downloadURL });
          setUserData((prev) => ({ ...prev, profilePicture: downloadURL }));
          setIsModalOpen(false);
        }
      );
    }
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  return (
    <ProfileWrapper>
      {userData && (
        <>
          <ProfileDetails>
            <ProfileImage
              src={userData.profilePicture || 'default-profile.png'}
              alt="Profile"
              onClick={handleImageClick}
            />
            <ProfileName>{userData.name}</ProfileName>
          </ProfileDetails>
          <MoreButton onClick={openModal}>더보기</MoreButton>

          <Modal isOpen={isModalOpen} onClose={closeModal} title="프로필 정보">
            <ModalProfileDetails>
              <ModalProfileImage
                src={userData.profilePicture || 'default-profile.png'}
                alt="Profile"
                onClick={handleImageClick}
              />
              <div>
                <ModalProfileName>{userData.name}</ModalProfileName>
              </div>
              <EditButton>수정하기</EditButton>
            </ModalProfileDetails>
            <FileInput
              ref={fileInputRef}
              type="file"
              accept="image/*"
              onChange={handleImageChange}
            />
            <UserInfo>
              <UserInfoItem><strong>Email:</strong> {userData.email}</UserInfoItem>
              <UserInfoItem><strong>Phone:</strong> {userData.phone || 'N/A'}</UserInfoItem>
              <UserInfoItem>
                <strong>가입일:</strong>{' '}
                {userData.createdAt
                  ? new Date(userData.createdAt.seconds * 1000).toLocaleDateString()
                  : 'N/A'}
              </UserInfoItem>
            </UserInfo>
          </Modal>
        </>
      )}
    </ProfileWrapper>
  );
};

export default ProfileInfo;
