import React from 'react';
import Header from '../components/common/Header';
import Product from '../components/Product/Product';
import Footer from '../components/common/Footer';

const ProductPage = () => (
  <>
    <Header />
    <Product />
    <Footer />
  </>
);

export default ProductPage;
