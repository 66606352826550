import React from 'react';

const DecisionDetails = ({ userName, selectedActivity, selectedRegion, hotelImageURL, hotelName }) => {
    const containerStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
    };

    const guideTextStyle = {
        fontSize: '1.5rem',
        color: '#333',
        marginBottom: '1rem',
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%'
    };

    const infoRowStyle = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: '1rem',
        gap: '1rem',
        maxWidth: '1200px',
        width: '100%',
    };

    const infoBoxStyle = {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
    };

    const imageStyle = {
        width: '100%',
        borderRadius: '10px',
        objectFit: 'cover',
        marginBottom: '0rem',
    };

    const titleStyle = {
        fontWeight: 'bold',
        fontSize: '1.2rem',
        marginBottom: '0rem',
    };

    return (
        <div style={containerStyle}>
            <p style={guideTextStyle}>{userName}님의 여행이 결정되었습니다.</p>
            <div style={infoRowStyle}>
                <div style={infoBoxStyle}>
                    <img src="https://via.placeholder.com/400x300" alt="Region" style={imageStyle} />
                    <p style={titleStyle}>{selectedRegion}</p>
                </div>
                <div style={infoBoxStyle}>
                    <img src="https://via.placeholder.com/400x300" alt="Activity" style={imageStyle} />
                    <p style={titleStyle}>{selectedActivity}</p>
                </div>
                <div style={infoBoxStyle}>
                    <img src={hotelImageURL} alt="Hotel" style={imageStyle} />
                    <p style={titleStyle}>{hotelName}</p>
                </div>
            </div>
        </div>
    );
};

export default DecisionDetails;
