import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

// Content 스타일
const Content = styled.div`
  display: flex;
  width: 100vw; /* 화면 가로 크기에 맞춤 */
  aspect-ratio: 16 / 9; /* 가로 세로 비율 설정 */
  max-height: 600px;
  justify-content: center;
  align-items: center;
`;

// SingleSection 스타일
const SingleSection = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  background-image: url('/assets/random_combined.jpg'); /* 단일 이미지 사용 */
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* 어두운 오버레이 */
  }

  & h2 {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    z-index: 1;
  }
`;

// RandomTour 컴포넌트
const RandomTour = () => {
  const navigate = useNavigate();

  return (
    <Content>
      <SingleSection onClick={() => navigate('/select-date-people')}>
        <h2>여행 계획<br/>시작하기</h2>
      </SingleSection>
    </Content>
  );
};

export default RandomTour;
