// src/components/Review/PopularByActivity.js

import React, { useState } from 'react';
import ChallengeSelect from './ChallengeSelect';
import ChallengeReviewList from './ChallengeReviewList';

const PopularByActivity = () => {
  const [selectedTheme, setSelectedTheme] = useState(null);
  const [selectedActivity, setSelectedActivity] = useState(null);

  const handleSelectChallenge = (themeId) => {
    setSelectedTheme(themeId);  // 선택된 테마 저장
  };

  const handleActivitySelect = (themeId, activityId) => {
    setSelectedTheme(themeId);
    setSelectedActivity(activityId);  // 선택된 활동 저장
  };

  return (
    <div>
      <ChallengeSelect
        onSelectChallenge={handleSelectChallenge}  // ChallengeSelect에 함수 전달
        onActivitySelect={handleActivitySelect}  // ChallengeSelect에 함수 전달
      />
      <ChallengeReviewList selectedTheme={selectedTheme} selectedActivity={selectedActivity} />
    </div>
  );
};

export default PopularByActivity;
