import React, { useEffect, useState } from 'react';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Badge from '@mui/material/Badge';
import FavoriteIcon from '@mui/icons-material/Favorite';
import StarIcon from '@mui/icons-material/Star';
import { red } from '@mui/material/colors';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';
import { collection, query, where, orderBy, onSnapshot, doc, updateDoc, arrayUnion, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import styled from 'styled-components';

const ReviewsContainer = styled.div`
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
`;

const fetchUserByUID = async (userID) => {
  const usersSnapshot = await getDocs(collection(db, "users"));
  let userData = null;
  usersSnapshot.forEach((doc) => {
    const userDoc = doc.data();
    if (userDoc.uid === userID) {
      userData = {
        name: userDoc.name,
        profilePicture: userDoc.profilePicture || 'https://via.placeholder.com/150',
      };
    }
  });
  return userData;
};

const Review = () => {
  const [reviews, setReviews] = useState([]);
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleLike = async (reviewId, currentLikes, likedUsers) => {
    if (!user) {
      alert('로그인이 필요합니다.');
      navigate('/login');
      return;
    }

    if (likedUsers && likedUsers.includes(user.uid)) {
      alert('이미 이 리뷰에 좋아요를 눌렀습니다.');
      return;
    }

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      await updateDoc(reviewRef, {
        likes: currentLikes + 1,
        likedUsers: arrayUnion(user.uid),
      });
    } catch (error) {
      console.error('좋아요 수 업데이트 중 오류 발생:', error);
    }
  };

  useEffect(() => {
    const fetchReviews = () => {
      const today = new Date();
      const oneWeekAgo = new Date(today.setDate(today.getDate() - 7));

      const q = query(
        collection(db, 'reviews'),
        where('createdAt', '>=', oneWeekAgo),
        orderBy('likes', 'desc'),
        orderBy('createdAt', 'desc')
      );

      onSnapshot(q, async (snapshot) => {
        const reviewsData = await Promise.all(
          snapshot.docs.map(async (docSnapshot) => {
            const reviewData = docSnapshot.data();
            const userData = await fetchUserByUID(reviewData.userID);
            return {
              id: docSnapshot.id,
              ...reviewData,
              userName: userData?.name,
              userProfile: userData?.profilePicture,
            };
          })
        );
        setReviews(reviewsData);
      });
    };

    fetchReviews();
  }, []);

  const handleReviewClick = (reviewId) => {
    navigate(`/review/${reviewId}`);
  };

  return (
    <ReviewsContainer>
      <Swiper slidesPerView={'auto'} spaceBetween={15} pagination={{ clickable: true }} modules={[Pagination]} style={{ padding: '1rem' }}>
        {reviews.map((review) => (
          <SwiperSlide key={review.id} style={{ width: '200px' }}>
            <Card sx={{ maxWidth: 300, marginBottom: '1rem' }}>
              <CardHeader
                avatar={
                  review.userProfile ? (
                    <Avatar src={review.userProfile} alt={review.userName} />
                  ) : (
                    <Avatar sx={{ bgcolor: red[500] }}>{review.userName?.charAt(0).toUpperCase()}</Avatar>
                  )
                }
                title={review.userName || 'Unknown User'}
                subheader={review.createdAt ? format(new Date(review.createdAt.seconds * 1000), 'yyyy-MM-dd') : '날짜 없음'}
              />
              {review.images && review.images.length > 0 && (
                <CardMedia
                  component="img"
                  height="194"
                  image={review.images[0]}
                  alt={review.title}
                  onClick={() => handleReviewClick(review.id)}
                  style={{ cursor: 'pointer' }}
                />
              )}
              <CardContent onClick={() => handleReviewClick(review.id)} style={{ cursor: 'pointer' }}>
                <Typography variant="h6" fontWeight="bold" color="text.primary">
                  {review.title}
                </Typography>
                <Typography variant="body3" color="text.primary">
                  {review.trip.selectedRegion}
                </Typography>
              </CardContent>
              <CardActions disableSpacing sx={{ justifyContent: 'space-between' }}>
                <IconButton
                  aria-label="add to favorites"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleLike(review.id, review.likes || 0, review.likedUsers || []);
                  }}
                  disabled={review.likedUsers && review.likedUsers.includes(user?.uid)}
                >
                  <Badge badgeContent={review.likes || 0} color="primary">
                    <FavoriteIcon />
                  </Badge>
                </IconButton>
                <Typography variant="body2" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  <StarIcon sx={{ color: '#ffd700', marginRight: '4px' }} />
                  {review.rating ? review.rating.toFixed(1) : '0.0'}
                </Typography>
              </CardActions>
            </Card>
          </SwiperSlide>
        ))}
      </Swiper>
    </ReviewsContainer>
  );
};

export default Review;
