import React, { useEffect, useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Rating from '@mui/material/Rating';
import { collection, query, where, getDocs, doc, deleteDoc, orderBy } from "firebase/firestore";
import { db } from '../../firebase';
import { useAuth } from '../../context/AuthContext';
import Modal from './Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Button from '@mui/material/Button';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const MyReviewsWrapper = styled.div`
  padding-left: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0 auto;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
`;

const addImageClass = (html) => {
  const div = document.createElement('div');
  div.innerHTML = html;

  const images = div.querySelectorAll('img');
  images.forEach(img => {
    img.classList.add('styled-image');
  });

  return div.innerHTML;
};

const formatDate = (timestamp) => {
  if (!timestamp) return '날짜 정보 없음';
  const date = new Date(timestamp.seconds * 1000);
  return date.toLocaleDateString();
};

const MyReviews = () => {
  const [reviews, setReviews] = useState([]);
  const { user } = useAuth();
  const [selectedReview, setSelectedReview] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();

  // fetchUserReviews를 useCallback으로 메모이제이션
  const fetchUserReviews = useCallback(async () => {
    if (!user) return;

    const reviewsQuery = query(
      collection(db, "reviews"),
      where("userID", "==", user.uid),
      orderBy("createdAt", "desc")
    );
    const querySnapshot = await getDocs(reviewsQuery);

    const userReviews = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    }));

    setReviews(userReviews);
  }, [user]);

  const handleEditReview = () => {
    if (selectedReview) {
      navigate(`/edit-review/${selectedReview.id}`, {
        state: { reviewId: selectedReview.id },
      });
    }
  };

  useEffect(() => {
    fetchUserReviews();
  }, [fetchUserReviews]);

  const openModal = (review) => {
    setSelectedReview(review);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleDeleteReview = async () => {
  if (selectedReview) {
    const confirmDelete = window.confirm('정말로 이 리뷰를 삭제하시겠습니까?');  // 확인 창 추가
    if (confirmDelete) {
      try {
        await deleteDoc(doc(db, "reviews", selectedReview.id));
        setReviews(reviews.filter(review => review.id !== selectedReview.id));
        closeModal();
      } catch (error) {
        console.error("리뷰 삭제 중 오류 발생: ", error);
      }
    }
  }
};

  return (
    <MyReviewsWrapper>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={15}
        pagination={{ clickable: true }}
        modules={[Pagination]}
        style={{ padding: '1rem', height: 280 }}
      >
        {reviews.map((review) => {
          const selectedRegion = review.trip?.selectedRegion || "지역 정보 없음";
          const startDate = formatDate(review.trip?.startDate);
          const endDate = formatDate(review.trip?.endDate);

          return (
            <SwiperSlide key={review.id} style={{ width: '200px', position: 'relative' }}>
              <Card sx={{ maxWidth: 345 }}>
                <CardActionArea onClick={() => openModal(review)}>
                  <CardMedia
                    component="img"
                    height="120"
                    image={review.images && review.images.length > 0 ? review.images[0] : '/static/images/cards/contemplative-reptile.jpg'}   // 이미지가 없을 경우 기본 이미지
                    alt="리뷰 이미지"
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {review.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                      {selectedRegion}
                    </Typography>
                    <Typography variant="body2" sx={{ whiteSpace: 'nowrap' }} color="text.secondary">
                       {`${startDate} ~ ${endDate}`}
                    </Typography>
                    <Rating value={review.rating} readOnly size="small" sx={{ marginTop: '0.5rem' }} />
                  </CardContent>
                </CardActionArea>
              </Card>
            </SwiperSlide>
          );
        })}
      </Swiper>

      <Modal isOpen={isModalOpen} onClose={closeModal} title={selectedReview ? selectedReview.title : ''}>
        {selectedReview && (
          <>
            <Typography variant="body1" gutterBottom>
              <strong>내용:</strong>
            </Typography>
            <Typography variant="body2" gutterBottom dangerouslySetInnerHTML={{ __html: addImageClass(selectedReview.content) }} />
            <ButtonContainer>
              <Button variant="contained" color="primary" onClick={handleEditReview}>
                리뷰 수정
              </Button>
              <Button variant="contained" color="error" onClick={handleDeleteReview}>
                리뷰 삭제
              </Button>
            </ButtonContainer>
          </>
        )}
      </Modal>
    </MyReviewsWrapper>
  );
};

export default MyReviews;
