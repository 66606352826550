import React from 'react';
import Header from '../components/common/Header';
import RandomTour from '../components/RandomTour/RandomTour';
import Footer from '../components/common/Footer';
import ServiceDescription from '../components/RandomTour/ServiceDescription';
import UserStatus from '../components/RandomTour/UserStatus';
import CheckUserData from '../components/auth/CheckUserData'; // CheckUserData 컴포넌트 가져오기

const RandomTourPage = () => (
    <>
        <Header isHome={false} pageTitle="랜덤 투어" /> {/* 페이지 제목을 props로 전달 */}
        {/* CheckUserData로 주요 콘텐츠 감싸기 */}
        <CheckUserData>
            <RandomTour />
            <ServiceDescription />
            <UserStatus />
        </CheckUserData>
        <Footer />
    </>
);

export default RandomTourPage;
