import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../common/Header';
import { db } from '../../firebase';
import { Timestamp, doc, collection, query, where, getDocs, updateDoc, addDoc } from 'firebase/firestore';
import { useAuth } from '../../context/AuthContext';
import axios from 'axios';
import DecisionDetails from '../../components/summary/DecisionDetails';
import Reviews from '../../components/summary/Reviews';
import LLMDetailedInfo from '../../components/summary/LLMDetailedInfo';
import styles from './Summary.module.css';

const loadKakaoSDK = () => {
  return new Promise((resolve, reject) => {
    if (!window.Kakao) {
      const script = document.createElement('script');
      script.src = 'https://developers.kakao.com/sdk/js/kakao.min.js';
      script.async = true;
      script.onload = () => {
        const kakaoAppKey = process.env.REACT_APP_KAKAO_APP_KEY;
        if (kakaoAppKey) {
          window.Kakao.init(kakaoAppKey);
          resolve(window.Kakao);
        } else {
          reject('Kakao App Key is missing!');
        }
      };
      script.onerror = () => reject('Failed to load Kakao SDK');
      document.head.appendChild(script);
    } else {
      resolve(window.Kakao);
    }
  });
};

const extractKoreanOrEnglish = (text) => {
  const korean = text ? text.match(/[가-힣\s]+/g)?.join(' ').trim() : '';
  const english = text ? text.match(/[a-zA-Z\s]+/g)?.join(' ').trim() : '';
  return korean || english || text; // 한글이 있으면 한글, 없으면 영어, 둘 다 없으면 원래 텍스트 반환
};

const Summary = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedTheme, selectedActivity, selectedRegion, selectedRegionId, hotelImageURL: initialHotelImageURL, hotelName: initialHotelName, hotelId, startDate, endDate, docId, fromProfile, numAdults, numChildren } = location.state || {};
    const [userName, setUserName] = useState('');
    const { user } = useAuth();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [hotelImageURL, setHotelImageURL] = useState(initialHotelImageURL || '');
    const [hotelName, setHotelName] = useState(initialHotelName ? extractKoreanOrEnglish(initialHotelName) : '');

    useEffect(() => {
        const fetchUserName = async () => {
            if (user) {
                const q = query(collection(db, 'users'), where('uid', '==', user.uid));
                const querySnapshot = await getDocs(q);
                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    setUserName(userData.name);
                }
            }
        };
        fetchUserName();

        loadKakaoSDK().then((Kakao) => {
        }).catch(error => console.error(error));
    }, [user]);

    useEffect(() => {
        const fetchHotelDetails = async () => {
            if (hotelId && (!initialHotelImageURL || !initialHotelName)) {
                try {
                    const requestBody = {
                        criteria: {
                            additional: {
                                currency: "KRW",
                                discountOnly: false,
                                language: "ko-kr",
                                occupancy: {
                                    numberOfAdult: 2,
                                    numberOfChildren: 0
                                }
                            },
                            checkInDate: startDate ? formatDate(new Date(startDate)) : '',
                            checkOutDate: endDate ? formatDate(new Date(endDate)) : '',
                            hotelId: [hotelId]
                        }
                    };

                    const headers = {
                        'Authorization': `${process.env.REACT_APP_AGODA_SITE_ID}:${process.env.REACT_APP_AGODA_API_KEY}`,
                        'Content-Type': 'application/json'
                    };

                    const response = await axios.post('/api/affiliateservice/lt_v1', requestBody, { headers });

                    if (response.status === 200 && response.data && Array.isArray(response.data.results)) {
                        const hotelDetails = response.data.results[0];
                        setHotelName(extractKoreanOrEnglish(hotelDetails.hotelName));
                        setHotelImageURL(hotelDetails.imageURL);
                    }
                } catch (error) {
                    console.error('Failed to fetch hotel details:', error);
                }
            }
        };

        fetchHotelDetails();
    }, [hotelId, initialHotelImageURL, initialHotelName, startDate, endDate]);

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleShareKakao = () => {
        if (window.Kakao && window.Kakao.Link) {
            window.Kakao.Link.sendDefault({
                objectType: 'feed',
                content: {
                    title: '여행 계획 결과',
                    description: `${userName}님이 선택한 여행 계획입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}, 숙소: ${hotelName}`,
                    imageUrl: hotelImageURL, // 대체 이미지 URL
                    link: {
                        mobileWebUrl: window.location.href,
                        webUrl: window.location.href,
                    },
                },
                buttons: [
                    {
                        title: '결과 보기',
                        link: {
                            mobileWebUrl: window.location.href,
                            webUrl: window.location.href,
                        },
                    },
                ],
            });
        } else {
            alert('Kakao SDK가 초기화되지 않았거나, Kakao.Link 객체가 준비되지 않았습니다.');
        }
    };

    const handleShareInstagram = () => {
        const url = `https://www.instagram.com/?url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    };

    const handleShareTwitter = () => {
        const text = `${userName}님이 선택한 여행 계획입니다!\n여행지: ${selectedRegion}, 활동: ${selectedActivity}`;
        const url = `https://twitter.com/intent/tweet?text=${encodeURIComponent(text)}&url=${encodeURIComponent(window.location.href)}`;
        window.open(url, '_blank');
    };

    const handleShare = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleSave = async () => {
  try {
    let tourResultRef;

    // startDate와 endDate가 타임스탬프 형식인지 확인하고 변환
    const formattedStartDate = startDate instanceof Timestamp ? startDate : Timestamp.fromDate(new Date(startDate));
    const formattedEndDate = endDate instanceof Timestamp ? endDate : Timestamp.fromDate(new Date(endDate));

    if (docId) {
      // docId가 있는 경우, 기존 문서를 업데이트
      tourResultRef = doc(db, 'tourResults', docId);
      await updateDoc(tourResultRef, {
        selectedTheme: selectedTheme,
        selectedActivity: selectedActivity,
        selectedRegion: selectedRegion,
        selectedRegionId: selectedRegionId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        hotelName: hotelName,
        hotelId: hotelId,
        numAdults: numAdults,
        numChildren: numChildren,
      });
      alert('수정되었습니다.');
    } else {
      // docId가 없는 경우, 새 문서를 생성
      const newDocRef = await addDoc(collection(db, 'tourResults'), {
        userID: user.uid,
        selectedTheme: selectedTheme,
        selectedActivity: selectedActivity,
        selectedRegion: selectedRegion,
        selectedRegionId: selectedRegionId,
        startDate: formattedStartDate,
        endDate: formattedEndDate,
        hotelName: hotelName,
        hotelId: hotelId,
        numAdults: numAdults,
        numChildren: numChildren,
        timestamp: Timestamp.now(),  // 현재 시간을 타임스탬프 형식으로 저장
      });

      // 새로 생성된 문서의 ID를 저장
      await updateDoc(newDocRef, {
        docId: newDocRef.id,  // 문서 ID를 Firestore에 저장
      });

      alert('새로운 여행 계획이 저장되었습니다.');
    }

    // 로컬 스토리지 정리
    localStorage.removeItem('selectedActivityName');
    localStorage.removeItem('selectedRegion');
    localStorage.removeItem('selectedRegionId');
    localStorage.removeItem('docId');

    navigate('/profile');

  } catch (e) {
    console.error('Error saving document: ', e);
    alert('저장 중 오류가 발생했습니다.');
  }
};

    const handleHomeNavigate = () => {
        navigate('/');
    };

    return (
        <>
            <Header pageTitle="여행 최종 안내"/>
            <div className={styles.summaryContainer}>
                <DecisionDetails userName={userName} selectedActivity={selectedActivity} selectedRegion={selectedRegion} hotelImageURL={hotelImageURL} hotelName={hotelName} />
                <Reviews />
                <LLMDetailedInfo />
                <div className={styles.buttonContainer}>
                    <button className={styles.shareButton} onClick={handleShare}>공유하기</button>
                    {fromProfile ? (
                        <button className={styles.saveButton} onClick={handleHomeNavigate}>홈으로 이동하기</button>
                    ) : (
                        <button className={styles.saveButton} onClick={handleSave}>저장하기</button>
                    )}
                </div>
            </div>

            {isModalOpen && (
                <div className={styles.modalOverlay}>
                    <div className={styles.modalContent}>
                        <div className={styles.modalHeader}>
                            <h2>일행과 공유하기</h2>
                            <button className={styles.closeModal} onClick={handleCloseModal}>X</button>
                        </div>
                        <div className={styles.modalBody}>
                            <img src="assets/kakao-logo.png" alt="Kakao" onClick={handleShareKakao} />
                            <img src="assets/instagram-logo.svg" alt="Instagram" onClick={handleShareInstagram} />
                            <img src="assets/x.svg" alt="Twitter" onClick={handleShareTwitter} />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default Summary;
