import React from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import Header from '../components/common/Header';
import ProfileInfo from '../components/Profile/ProfileInfo';
import MyTrips from '../components/Profile/MyTrips';
import MyReviews from '../components/Profile/MyReviews';
import Coupons from '../components/Profile/Coupons';
import { useAuth } from '../context/AuthContext';
import CheckUserData from '../components/auth/CheckUserData'; // CheckUserData 컴포넌트 가져오기

const ProfileWrapper = styled.div`
  max-width: 1200px; /* 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
  padding: 0 0.5rem; /* 좌우 패딩 */
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0;  /* 작은 화면에서는 여백을 줄임 */
    overflow: hidden;
  }
`;

const Title = styled.h2`
  margin-bottom: 1rem;
  margin-top: 1rem;
  margin-left: 1rem;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
`;

const LogoutButton = styled.button`
  width: 100%;
  max-width: 800px;
  padding: 0.8rem;
  margin-top: 0;
  border: none;
  font-size: 14px;
  cursor: pointer;
  background-color: red;
  color: white;
  &:hover {
    opacity: 0.8;
  }

  @media (min-width: 800px) {
    border-radius: 50px; /* 너비가 800px 이상일 때 둥글게 변경 */
  }
`;

const Profile = () => {
    const { logout } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        try {
            await logout();
            navigate('/login');
        } catch (error) {
            console.error('Logout failed', error);
        }
    };

    return (
        <>
            <Header pageTitle="프로필"/>
            <CheckUserData> {/* 사용자 정보 확인을 위해 CheckUserData로 감싸기 */}
                <ProfileWrapper>
                    <ProfileInfo />
                    <Title>나의 여행</Title>
                    <MyTrips />
                    <Title>나의 리뷰</Title>
                    <MyReviews />
                    <Title>쿠폰함</Title>
                    <Coupons />
                    <ButtonContainer>
                        <LogoutButton onClick={handleLogout}>로그아웃</LogoutButton>
                    </ButtonContainer>
                </ProfileWrapper>
            </CheckUserData>
        </>
    );
};

export default Profile;
