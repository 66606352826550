import React, { useState } from 'react';
import styled from 'styled-components';
import Header from '../common/Header';
import { auth, db } from '../../firebase';
import { sendPasswordResetEmail } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  &:hover {
    opacity: 0.8;
  }
  margin-top: 0.5rem;
`;

const Message = styled.p`
  font-size: 14px;
  color: #007bff;
`;

const Error = styled.p`
  color: red;
  font-size: 14px;
`;

const ForgotPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');
  const [error, setError] = useState('');
  const [showEmailInput, setShowEmailInput] = useState(true);
  const navigate = useNavigate();

  const handleEmailChange = (e) => setEmail(e.target.value);

  const handlePasswordReset = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const q = query(collection(db, 'users'), where('email', '==', email));
      const querySnapshot = await getDocs(q);
      if (querySnapshot.empty) {
        setError('등록된 이메일이 없습니다.');
      } else {
        await sendPasswordResetEmail(auth, email);
        setMessage(`비밀번호 변경 링크가 ${email}로 전송되었습니다.`);
        setShowEmailInput(false);
      }
    } catch (error) {
      console.error('Error sending email: ', error);
      setError('이메일 전송 중 오류가 발생했습니다.');
    }
  };

  const openEmailDomain = () => {
    const emailDomain = email.split('@')[1];
    window.open(`http://${emailDomain}`, '_blank');
  };

  const goToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <FormWrapper>
          <Title>비밀번호 찾기</Title>
          {showEmailInput ? (
            <form onSubmit={handlePasswordReset}>
              <InputWrapper>
                <Label htmlFor="email">이메일:</Label>
                <Input
                  type="email"
                  id="email"
                  value={email}
                  onChange={handleEmailChange}
                  required
                />
              </InputWrapper>
              <Button type="submit">비밀번호 변경</Button>
              {error && <Error>{error}</Error>}
            </form>
          ) : (
            <>
              <Message>{message}</Message>
              <Button onClick={openEmailDomain}>이메일 홈페이지 열기</Button>
              <Button onClick={goToHome}>홈으로 돌아가기</Button>
            </>
          )}
        </FormWrapper>
      </PageWrapper>
    </>
  );
};

export default ForgotPasswordPage;
