import React, { useState } from 'react';
import styled from 'styled-components';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';
import Header from '../common/Header';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 2rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: bold;
`;

const InputWrapper = styled.div`
  width: 100%;
  margin: 0.5rem 0;
  text-align: left;
`;

const Label = styled.label`
  display: block;
  margin-bottom: 0.5rem;
  font-size: 14px;
  color: #333;
`;

const Input = styled.input`
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  box-sizing: border-box;
  &:not([type='submit']) {
    margin-right: 0.5rem;
  }
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const Error = styled.p`
  color: red;
  font-size: 14px;
`;

const ForgotIDPage = () => {
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const handleChange = (e, setFunction) => {
    setFunction(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const phone = `${phonePart1}-${phonePart2}-${phonePart3}`;

    try {
      const q = query(collection(db, 'users'), where('phone', '==', phone));
      const querySnapshot = await getDocs(q);
      if (!querySnapshot.empty) {
        const userData = querySnapshot.docs[0].data();
        setEmail(userData.email);
      } else {
        setError('해당 휴대전화 번호로 등록된 아이디가 없습니다.');
      }
    } catch (error) {
      console.error('Error finding ID: ', error);
      setError('아이디 찾기 중 오류가 발생했습니다.');
    }
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <FormWrapper>
          <Title>아이디 찾기</Title>
          <form onSubmit={handleSubmit}>
            <InputWrapper>
              <Label htmlFor="phone">휴대전화 번호:</Label>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Input
                  type="tel"
                  id="phonePart1"
                  placeholder="010"
                  value={phonePart1}
                  onChange={(e) => handleChange(e, setPhonePart1)}
                  required
                  maxLength={3}
                  style={{ width: '30%' }}
                />
                -
                <Input
                  type="tel"
                  id="phonePart2"
                  placeholder="1234"
                  value={phonePart2}
                  onChange={(e) => handleChange(e, setPhonePart2)}
                  required
                  maxLength={4}
                  style={{ width: '30%' }}
                />
                -
                <Input
                  type="tel"
                  id="phonePart3"
                  placeholder="5678"
                  value={phonePart3}
                  onChange={(e) => handleChange(e, setPhonePart3)}
                  required
                  maxLength={4}
                  style={{ width: '30%' }}
                />
              </div>
            </InputWrapper>
            <Button type="submit">아이디 찾기</Button>
          </form>
          {email && (
            <InputWrapper>
              <p>아이디: {email}</p>
            </InputWrapper>
          )}
          {error && (
            <InputWrapper>
              <Error>{error}</Error>
            </InputWrapper>
          )}
        </FormWrapper>
      </PageWrapper>
    </>
  );
};

export default ForgotIDPage;
