import React from 'react';
import styled from 'styled-components';

const FooterWrapper = styled.footer`
  padding: 1rem;
  background-color: white;
  color: #61788A;
  text-align: center;
    border-top: 1px solid #ccc;
`;

const Footer = () => (
  <FooterWrapper>
    <p>Company Introduction</p>
    <p>Follow us on social media</p>
  </FooterWrapper>
);

export default Footer;
