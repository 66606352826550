import React, { useState, useEffect } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { FaUserCircle, FaBars, FaTimes, FaArrowLeft } from 'react-icons/fa';
import { useAuth } from '../../context/AuthContext';
import './Header.css';

const Header = ({ pageTitle }) => {
  const { user } = useAuth();
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth < 769);
  const navigate = useNavigate();
  const location = useLocation();

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!isMobileMenuOpen);
  };

  const handleProfileClick = () => {
    if (user) {
      navigate('/profile');
    } else {
      navigate('/login');
    }
  };

  const handleBackClick = () => {
    navigate(-1);
  };

  const isHome = location.pathname === '/';

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth < 769);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <header className="header-wrapper">
      <div className="logo-or-back">
        {isMobileView && !isHome ? (
          <button className="back-button" onClick={handleBackClick}>
            <FaArrowLeft size={24} color="#004B80" />
          </button>
        ) : (
          <Link to="/" className="home-icon">
            <img src="/assets/logo.png" alt="Logo" className="logo" />
          </Link>
        )}
      </div>

      <div className="mobile-title">{pageTitle}</div>

      <nav className="menu">
        <Link to="/random-tour" className="menu-item">랜덤투어</Link>
        <Link to="/review" className="menu-item">리뷰</Link>
        <Link to="/toursight" className="menu-item">관광지</Link>
        <Link to="/product" className="menu-item">여행상품</Link>
      </nav>

      <div className="auth">
        <div className="auth-icon" onClick={handleProfileClick}>
          <FaUserCircle size={24} />
        </div>
        <div className="mobile-menu-icon" onClick={toggleMobileMenu}>
          {isMobileMenuOpen ? <FaTimes size={24} /> : <FaBars size={24} />}
        </div>
      </div>

      {isMobileMenuOpen && (
        <nav className="mobile-menu">
          <Link to="/" className="mobile-menu-item" onClick={toggleMobileMenu}>홈</Link>
          <Link to="/random-tour" className="mobile-menu-item" onClick={toggleMobileMenu}>랜덤투어</Link>
          <Link to="/review" className="mobile-menu-item" onClick={toggleMobileMenu}>리뷰</Link>
          <Link to="/toursight" className="mobile-menu-item" onClick={toggleMobileMenu}>관광지</Link>
          <Link to="/product" className="mobile-menu-item" onClick={toggleMobileMenu}>여행상품</Link>
        </nav>
      )}
    </header>
  );
};

export default Header;
