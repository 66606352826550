import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion } from 'firebase/firestore';
import { db } from '../../firebase';
import Header from '../../components/common/Header';
import Footer from '../../components/common/Footer';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';
import { Card, CardContent, CardMedia, Typography, Box, CircularProgress, Container, Grid, IconButton } from '@mui/material';
import { Rating } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import { useAuth } from '../../context/AuthContext'; // 로그인 상태 확인

const ReviewDetailPage = () => {
  const { reviewId } = useParams();
  const [review, setReview] = useState(null);
  const [authorName, setAuthorName] = useState('');
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const { user } = useAuth(); // 로그인한 사용자 정보

  useEffect(() => {
    const fetchReview = async () => {
      const reviewDoc = await getDoc(doc(db, 'reviews', reviewId));
      if (reviewDoc.exists()) {
        const reviewData = reviewDoc.data();
        setReview(reviewData);
        if (reviewData.userID) {
          fetchAuthorName(reviewData.userID);
        }
      } else {
        console.log('리뷰를 찾을 수 없습니다.');
      }
    };

    const fetchAuthorName = async (userID) => {
      const usersQuery = query(collection(db, 'users'), where('uid', '==', userID));
      const userSnapshot = await getDocs(usersQuery);
      if (!userSnapshot.empty) {
        const userData = userSnapshot.docs[0].data();
        setAuthorName(userData.name || '알 수 없음');
      } else {
        setAuthorName('알 수 없음');
      }
    };

    fetchReview();
  }, [reviewId]);

  if (!review) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', marginTop: 5 }}>
        <CircularProgress />
        <Typography variant="body1" sx={{ marginTop: 2 }}>리뷰를 불러오는 중입니다...</Typography>
      </Container>
    );
  }

  const handleImageClick = (index) => {
    setCurrentImageIndex(index);
    setIsLightboxOpen(true);
  };

  const formatDate = (timestamp) => {
    if (!timestamp) return '';
    const date = timestamp.toDate();
    return date.toLocaleDateString();
  };

  const handleLikeClick = async () => {
    if (!user) {
      alert('로그인이 필요합니다.');
      return;
    }

    if (review.likedUsers && review.likedUsers.includes(user.uid)) {
      alert('이미 좋아요를 눌렀습니다.');
      return;
    }

    try {
      const reviewRef = doc(db, 'reviews', reviewId);
      await updateDoc(reviewRef, {
        likes: (review.likes || 0) + 1,
        likedUsers: arrayUnion(user.uid),
      });

      // 상태 업데이트
      setReview((prevReview) => ({
        ...prevReview,
        likes: (prevReview.likes || 0) + 1,
        likedUsers: [...(prevReview.likedUsers || []), user.uid],
      }));
    } catch (error) {
      console.error('좋아요 업데이트 실패:', error);
    }
  };

  return (
    <>
      <Header />
      <Container maxWidth="md" sx={{ marginTop: 4 }}>
        <Card>
          <CardContent>
            <Typography variant="h4" component="div" gutterBottom>
              {review.title}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary" gutterBottom>
              작성자: {authorName} | 작성일: {review.createdAt ? formatDate(review.createdAt) : '정보 없음'}
            </Typography>

            <Box sx={{ marginTop: 3, marginBottom: 3 }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>장소:</strong> {review.trip?.selectedRegion || '정보 없음'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>활동:</strong> {review.trip?.selectedActivity || '정보 없음'}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">
                    <strong>여행일자:</strong>
                    {review.trip?.startDate ? formatDate(review.trip.startDate) : '정보 없음'} ~
                    {review.trip?.endDate ? formatDate(review.trip.endDate) : '정보 없음'}
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Typography variant="body2" color="textSecondary">
                    <strong>별점:</strong>
                  </Typography>
                  <Rating value={review.rating} readOnly precision={0.5} />
                </Grid>
              </Grid>
            </Box>

            <Typography variant="body1" paragraph>
              {review.content}
            </Typography>

            {/* 좋아요 및 좋아요 수 표시 */}
            <Box display="flex" alignItems="center" gap={1}>
              <IconButton aria-label="like" onClick={handleLikeClick} disabled={review.likedUsers && review.likedUsers.includes(user?.uid)}>
                <FavoriteIcon color={review.likedUsers && review.likedUsers.includes(user?.uid) ? "error" : "default"} />
              </IconButton>
              <Typography variant="body2">{review.likes || 0}</Typography>
            </Box>

            {review.images && review.images.length > 0 && (
              <>
                <Box sx={{ display: 'flex', gap: 2, overflowX: 'auto', mt: 3 }}>
                  {review.images.map((url, index) => (
                    <CardMedia
                      key={index}
                      component="img"
                      image={url}
                      alt={`Review Image ${index}`}
                      onClick={() => handleImageClick(index)}
                      sx={{ maxHeight: 150, cursor: 'pointer', borderRadius: 2, objectFit: 'cover' }}
                    />
                  ))}
                </Box>

                <Lightbox
                  open={isLightboxOpen}
                  close={() => setIsLightboxOpen(false)}
                  slides={review.images.map((src) => ({ src }))}
                  index={currentImageIndex}
                  onIndexChange={setCurrentImageIndex}
                />
              </>
            )}
          </CardContent>
        </Card>
      </Container>
      <Footer />
    </>
  );
};

export default ReviewDetailPage;
