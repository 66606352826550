import React from 'react';

const UserStatus = () => {
    const containerStyle = {
        padding: '1.5rem',
        backgroundColor: '#f1f1f1',
        textAlign: 'center'
    };

    const textStyle = {
        fontSize: '1rem',
        color: '#666'
    };

    return (
        <div style={containerStyle}>
            <p style={textStyle}>현재까지 100,000명의 이용자가 랜덤 투어를 이용하였습니다.</p>
        </div>
    );
};

export default UserStatus;

