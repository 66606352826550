import React from 'react';
import Header from '../components/common/Header';
import Toursight from '../components/Toursights/Toursight';
import Footer from '../components/common/Footer';

const ToursightPage = () => (
  <>
    <Header />
    <Toursight />
    <Footer />
  </>
);

export default ToursightPage;
