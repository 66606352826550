import React from 'react';

const ServiceDescription = () => {
    const containerStyle = {
        padding: '1.5rem',
        backgroundColor: '#f9f9f9',
        textAlign: 'center'
    };

    const textStyle = {
        fontSize: '1rem',
        color: '#666'
    };

    return (
        <div style={containerStyle}>
            <p style={textStyle}>랜덤투어는 룰렛으로 여행지를 무작위로 정해 쉽고 빠르게, 색다른 여행을 제안합니다.</p>
        </div>
    );
};

export default ServiceDescription;