// src/components/Review/PopularByRegion.js

import React, { useState } from 'react';
import RegionSelect from './RegionSelect';
import RegionsReviewList from './RegionsReviewList';

const PopularByRegion = () => {
  const [selectedRegion, setSelectedRegion] = useState(null); // 선택된 지역 상태

  // 지역 선택 시 호출되는 함수
  const handleSelectRegion = (region) => {
    setSelectedRegion(region); // 선택된 지역을 업데이트
  };

  return (
    <div>
      <RegionSelect onSelectRegion={handleSelectRegion} /> {/* 지역 선택 컴포넌트 */}
      {selectedRegion && <RegionsReviewList selectedRegion={selectedRegion} />} {/* 선택된 지역의 리뷰 목록 */}
    </div>
  );
};

export default PopularByRegion;
