// src/components/Profile/Modal.js

import React from 'react';
import styled from 'styled-components';

// 모달 오버레이
const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow: auto;
`;

// 모달 컨테이너
const ModalContainer = styled.div`
  width: 90%;
  max-width: 500px;
  margin: 0 auto;
  overflow: hidden;
`;

// 모달 헤더
const ModalHeader = styled.div`
  background-color: #0096FF;
  padding: 1rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

// 모달 타이틀
const ModalTitle = styled.h2`
  margin: 0;
  color: white;
`;

// 닫기 버튼
const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: white;
  &:hover {
    color: #f0f0f0;
  }
`;

// 모달 콘텐츠
const ModalContent = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 0 0 8px 8px;
  text-align: center;
`;

// 모달 컴포넌트
const Modal = ({ isOpen, onClose, title, children }) => {
  if (!isOpen) return null;

  return (
    <ModalOverlay onClick={onClose}>
      <ModalContainer onClick={(e) => e.stopPropagation()}>
        <ModalHeader>
          <ModalTitle>{title}</ModalTitle>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </ModalHeader>
        <ModalContent>{children}</ModalContent>
      </ModalContainer>
    </ModalOverlay>
  );
};

export default Modal;
