import React, { useEffect, useState, useRef, useCallback } from "react";
import { collection, getDocs, doc, getDoc } from "firebase/firestore";
import { db } from "../../firebase";
import Header from "../common/Header";
import { useNavigate, useLocation } from "react-router-dom";
import { Wheel } from "spin-wheel";
import { easeCubicOut } from "d3-ease";
import styles from "./Challenge.module.css"; // Using CSS Module for styles

const Challenge = () => {
  const [themes, setThemes] = useState([]); // State to hold the list of themes
  const [selectedTheme, setSelectedTheme] = useState(null); // State to hold the selected theme
  const [activities, setActivities] = useState([]); // State to hold the list of activities under the selected theme
  const [wheelActivities, setWheelActivities] = useState([]); // State to hold the activities on the wheel
  const [isSpinning, setIsSpinning] = useState(false); // State to track if the wheel is spinning
  const [isStopping, setIsStopping] = useState(false); // State to track if the wheel is in the process of stopping
  const [prizeNumber, setPrizeNumber] = useState(0); // State to track the prize number
  const [buttonLabel, setButtonLabel] = useState("랜덤 선택 시작"); // State to track button label
  const [showNextButton, setShowNextButton] = useState(false); // State to toggle between "Stop" and "Next" buttons
  const navigate = useNavigate();
  const location = useLocation();
  const { fromRegionRoulette, startDate, endDate, numAdults, numChildren, selectedRegion, selectedRegionId, docId } = location.state || {}; // Extract selectedRegionId from location.state
  const wheelContainerRef = useRef(null); // Reference to the wheel container
  const wheelInstance = useRef(null); // Reference to the wheel instance

  useEffect(() => {
    const fetchThemes = async () => {
      try {
        let themeList = [];

        if (selectedRegionId) {
          // Fetch themes based on selectedRegionId
          const regionDocRef = doc(db, "regions", selectedRegionId);
          const regionDoc = await getDoc(regionDocRef);

          if (regionDoc.exists()) {
            const data = regionDoc.data();
            const themeReferences = data.theme_references || [];

            const themePromises = themeReferences.map(async (themeRef) => {
              const themeDoc = await getDoc(themeRef);
              if (themeDoc.exists()) {
                const activitiesCollectionRef = collection(db, "themes", themeDoc.id, "activities");
                const activitiesSnapshot = await getDocs(activitiesCollectionRef);
                const activitiesList = activitiesSnapshot.docs.map((doc) => ({
                  id: doc.id,
                  name: doc.data().activity_name,
                }));

                return {
                  id: themeDoc.id,
                  name: themeDoc.data().ko,
                  activities: activitiesList,
                };
              }
              return null;
            });

            themeList = (await Promise.all(themePromises)).filter((theme) => theme !== null);
          } else {
            console.error("No such region document!");
          }
        } else {
          // Fetch all themes
          const themesCollection = collection(db, "themes");
          const themesSnapshot = await getDocs(themesCollection);
          themeList = themesSnapshot.docs.map((doc) => ({
            id: doc.id,
            name: doc.data().ko, // Fetch the 'ko' field for the theme name
          }));
        }

        setThemes(themeList);
      } catch (error) {
        console.error("Error fetching themes:", error);
      }
    };

    fetchThemes();
  }, [selectedRegionId]);

  const fetchActivities = async (themeId) => {
    try {
      const activitiesCollection = collection(db, "themes", themeId, "activities");
      const activitiesSnapshot = await getDocs(activitiesCollection);
      const activityList = activitiesSnapshot.docs.map((doc) => ({
        id: doc.id,
        name: doc.data().activity_name,
        themeId, // Store the themeId to match with the theme later
      }));
      setActivities(activityList);
      setSelectedTheme(themes.find((theme) => theme.id === themeId));
    } catch (error) {
      console.error("Error fetching activities:", error);
    }
  };

  const initializeWheel = useCallback(() => {
    if (wheelContainerRef.current && wheelActivities.length > 0) {
      const wheelProps = {
        items: wheelActivities.map((activity) => ({
          label: activity.name,
          backgroundColor: "#0096FF",
          labelColor: "#FFFFFF",
        })),
        radius: 0.9,
        lineWidth: 3,
        lineColor: "#FFFFFF",
        pointerAngle: 0,
        itemLabelFont: "Arial",
        itemLabelFontSizeMax: 30,
        itemLabelRadius: 0.6,
        itemLabelAlign: "center",
        itemLabelRotation: 0,
        isInteractive: false,
        rotationResistance: 0,
        debug: false,
      };

      if (!wheelInstance.current) {
        wheelInstance.current = new Wheel(wheelContainerRef.current, wheelProps);
      } else {
        wheelInstance.current.init(wheelProps);
      }
    } else if (wheelContainerRef.current && wheelActivities.length === 0) {
      if (wheelInstance.current) {
        wheelInstance.current.remove();
        wheelInstance.current = null;
      }
    }
  }, [wheelActivities]);

  useEffect(() => {
    initializeWheel();
  }, [wheelActivities, initializeWheel]);

  const startSpin = () => {
    if (!isSpinning && wheelActivities.length > 0 && wheelInstance.current) {
      setIsSpinning(true);
      setShowNextButton(false);
      wheelInstance.current.spin(3000);
    }
  };

  const stopSpin = () => {
    if (isSpinning && wheelInstance.current) {
      setIsStopping(true);
      const winningItemIndex = Math.floor(Math.random() * wheelActivities.length);
      const duration = 4000;

      try {
        wheelInstance.current.spinToItem(
          winningItemIndex,
          duration,
          true,
          1,
          1,
          easeCubicOut
        );

        setPrizeNumber(winningItemIndex);

        setTimeout(() => {
          setIsSpinning(false);
          setIsStopping(false);
          setShowNextButton(true);
          alert(`${wheelActivities[winningItemIndex].name}이 당첨되었습니다!`);
        }, duration);
      } catch (error) {
        console.error("Error stopping the wheel:", error);
        setIsSpinning(false);
        setIsStopping(false);
      }
    }
  };

  const handleNextStep = () => {
  if (wheelActivities[prizeNumber]) {
    const selectedActivity = wheelActivities[prizeNumber];
    const theme = themes.find((t) => t.id === selectedActivity.themeId);

    navigate("/first-result", {
      state: {
        docId: docId || null,
        fromChallengeRoulette: true,
        fromRegionRoulette,
        startDate,
        endDate,
        numAdults,
        numChildren,
        selectedRegion,
        selectedRegionId,
        selectedActivityName: selectedActivity.name,
        selectedTheme: theme.name,
      },
    });
  }
};


  const handleActivityClick = (activity) => {
    if (wheelActivities.find((a) => a.id === activity.id)) {
      // Remove from wheel
      setWheelActivities((prev) => prev.filter((a) => a.id !== activity.id));
    } else {
      // Add to wheel
      setWheelActivities((prev) => [...prev, activity]);
    }
  };

  const InfoBox = ({ startDate, endDate, numAdults, numChildren }) => {
  // startDate와 endDate가 Date 객체가 아니면 변환
  const formattedStartDate = startDate instanceof Date ? startDate : new Date(startDate);
  const formattedEndDate = endDate instanceof Date ? endDate : new Date(endDate);

  return (
    <div className="infoBoxContainer">
      <div className="infoItem">
        <strong>여행 시작일:</strong> {formattedStartDate.toLocaleDateString()}
      </div>
      <div className="infoItem">
        <strong>여행 종료일:</strong> {formattedEndDate.toLocaleDateString()}
      </div>
      <div className="infoItem">
        <strong>성인 수:</strong> {numAdults}명
      </div>
      <div className="infoItem">
        <strong>어린이 수:</strong> {numChildren}명
      </div>
    </div>
  );
};

  return (
    <>
      <Header pageTitle="챌린지 룰렛" />
      <InfoBox
        startDate={startDate}
        endDate={endDate}
        numAdults={numAdults}
        numChildren={numChildren}
      />
      <div className={styles.rouletteContainer}>
        <h1 className={styles.heading}>
          {selectedRegion ? `${selectedRegion}에서 무엇을 하게 될까요?` : "무엇을 할게 될까요?"}
        </h1>
        <div className={styles.activitiesList}>
          {themes.map((theme) => (
              <div
                  key={theme.id}
              className={styles.draggableItem}
              style={{
                backgroundColor: selectedTheme?.id === theme.id ? "#e0e0e0" : "#4285F4",
                color: selectedTheme?.id === theme.id ? "#888" : "white",
                cursor: "pointer",
              }}
              onClick={() => fetchActivities(theme.id)}
            >
              {theme.name}
            </div>
          ))}
        </div>
        {selectedTheme && (
          <div className={styles.activitiesList}>
            {activities.map((activity) => (
              <div
                key={activity.id}
                className={styles.draggableItem}
                style={{
                  backgroundColor: wheelActivities.find((a) => a.id === activity.id)
                    ? "#e0e0e0"
                    : "#4285F4",
                  color: wheelActivities.find((a) => a.id === activity.id)
                    ? "#888"
                    : "white",
                  cursor: "pointer",
                }}
                onClick={() => handleActivityClick(activity)}
              >
                {activity.name}
              </div>
            ))}
          </div>
        )}
        <div className={styles.indicator}></div>
        <div ref={wheelContainerRef} className={styles.wheelContainer}></div>
      </div>
      <div className={styles.buttonWrapper}>
        <button
          className={`${styles.rouletteButton} ${
            isSpinning || wheelActivities.length === 0 ? styles.disabled : ""
          }`}
          onClick={() => {
            startSpin();
            setButtonLabel("흠 다시...");
          }}
          disabled={isSpinning || wheelActivities.length === 0}
        >
          {buttonLabel}
        </button>
        {showNextButton ? (
          <button className={styles.rouletteButton} onClick={handleNextStep}>
            다음 단계
          </button>
        ) : (
          <button
            className={`${styles.rouletteButton} ${
              !isSpinning || isStopping ? styles.disabled : ""
            }`}
            onClick={stopSpin}
            disabled={!isSpinning || isStopping}
          >
            랜덤 선택 멈춤
          </button>
        )}
      </div>
    </>
  );
};

export default Challenge;
