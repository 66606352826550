import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../common/Header';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
  padding-top: 40px;
  padding-bottom: 40px;
`;

const FormWrapper = styled.div`
  background-color: white;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  width: 100%;
  max-width: 320px;
  margin: 0 auto;
  text-align: center;
`;

const Title = styled.h2`
  margin-bottom: 2rem;
  font-size: 24px;
  font-weight: bold;
`;

const Message = styled.p`
  font-size: 16px;
  color: #333;
  margin-bottom: 2rem;
`;

const Button = styled.button`
  width: 100%;
  padding: 0.8rem;
  border: none;
  border-radius: 20px;
  font-size: 14px;
  cursor: pointer;
  background-color: #007bff;
  color: white;
  &:hover {
    opacity: 0.8;
  }
`;

const SignUpComplete = () => {
  const navigate = useNavigate();

  const goToHome = () => {
    navigate('/');
  };

  return (
    <>
      <Header />
      <PageWrapper>
        <FormWrapper>
          <Title>회원가입 완료</Title>
          <Message>회원가입이 완료되었습니다!</Message>
          <Button onClick={goToHome}>메인페이지로 이동</Button>
        </FormWrapper>
      </PageWrapper>
    </>
  );
};

export default SignUpComplete;
