import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import Header from '../common/Header';
import axios from 'axios';
import styles from './SearchAccommodations.module.css';
import { db } from '../../firebase'; // Firestore 인스턴스 임포트

const SearchAccommodations = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { selectedTheme, selectedActivity, selectedRegion, selectedRegionId, startDate, endDate, numAdults, numChildren, docId } = location.state || {};
    const [hotels, setHotels] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [page, setPage] = useState(1);
    const [cityId, setCityId] = useState(null); // Firestore에서 가져올 cityId 상태
    const [sortBy, setSortBy] = useState('Recommended'); // 정렬 기준 상태 추가
    const [selectedHotel, setSelectedHotel] = useState(null); // 선택된 호텔 상태 추가
    const observer = useRef();

    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    // Firestore에서 cityId 가져오기
    useEffect(() => {
        const fetchCityId = async () => {
            try {
                const regionDocRef = doc(db, 'regions', selectedRegionId);
                const regionDoc = await getDoc(regionDocRef);

                if (regionDoc.exists()) {
                    const data = regionDoc.data();
                    const agodaId = data.agodaID; // agodaID 값을 가져옴
                    setCityId(agodaId); // cityId로 설정
                } else {
                    console.error('No such document!');
                }
            } catch (err) {
                console.error('Failed to fetch cityId:', err);
            }
        };

        if (selectedRegionId) {
            fetchCityId();
        }
    }, [selectedRegionId]);

    const fetchHotels = useCallback(async () => {
        if (!cityId || hotels.length >= 30) return; // cityId가 없거나 이미 30개 이상의 호텔이 있으면 추가 요청을 하지 않음

        setLoading(true);
        setError('');
        try {
            const startDateString = startDate ? formatDate(new Date(startDate)) : '';
            const endDateString = endDate ? formatDate(new Date(endDate)) : '';
            const requestBody = {
                criteria: {
                    additional: {
                        currency: "USD",
                        dailyRate: {
                            maximum: 10000,
                            minimum: 1
                        },
                        discountOnly: false,
                        language: "ko-kr",
                        maxResult: 30,
                        minimumReviewScore: 0,
                        minimumStarRating: 0,
                        occupancy: {
                            numberOfAdult: numAdults,
                            numberOfChildren: numChildren
                        },
                        sortBy: sortBy,
                    },
                    checkInDate: startDateString,
                    checkOutDate: endDateString,
                    cityId: cityId
                }
            };

            const headers = {
                'Authorization': `${process.env.REACT_APP_AGODA_SITE_ID}:${process.env.REACT_APP_AGODA_API_KEY}`,
                'Content-Type': 'application/json'
            };

            const response = await axios.post('/api/affiliateservice/lt_v1', requestBody, { headers });

            if (response.status === 200 && response.data && Array.isArray(response.data.results)) {
                const newHotels = response.data.results;

                // 중복된 호텔 제거
                const filteredHotels = newHotels.filter(
                    newHotel => !hotels.some(existingHotel => existingHotel.hotelId === newHotel.hotelId)
                );

                // 원화로 가격 정보를 가져오기 위한 추가 요청
                const hotelDetailsPromises = filteredHotels.map(hotel => fetchHotelDetailsInKRW(hotel.hotelId));
                const hotelDetailsInKRW = await Promise.all(hotelDetailsPromises);

                const updatedHotels = filteredHotels.map((hotel, index) => ({
                    ...hotel,
                    dailyRate: hotelDetailsInKRW[index]?.dailyRate || hotel.dailyRate,
                    currency: hotelDetailsInKRW[index]?.currency || hotel.currency,
                }));

                setHotels(prevHotels => [...prevHotels, ...updatedHotels].slice(0, 30)); // 최대 30개의 호텔만 유지
            } else {
                setHotels([]); // 결과가 없을 경우 빈 배열 설정
                setError('No results found.');
            }
        } catch (err) {
            console.error(err);
            setError('Failed to fetch hotels.');
        } finally {
            setLoading(false);
        }
    }, [numAdults, numChildren, startDate, endDate, cityId, hotels, sortBy]);

    const fetchHotelDetailsInKRW = async (hotelId) => {
        try {
            const startDateString = startDate ? formatDate(new Date(startDate)) : '';
            const endDateString = endDate ? formatDate(new Date(endDate)) : '';
            const requestBody = {
                criteria: {
                    additional: {
                        currency: "KRW",
                        discountOnly: false,
                        language: "ko-kr",
                        occupancy: {
                            numberOfAdult: numAdults,
                            numberOfChildren: numChildren
                        }
                    },
                    checkInDate: startDateString,
                    checkOutDate: endDateString,
                    hotelId: [hotelId]
                }
            };

            const headers = {
                'Authorization': `${process.env.REACT_APP_AGODA_SITE_ID}:${process.env.REACT_APP_AGODA_API_KEY}`,
                'Content-Type': 'application/json'
            };

            const response = await axios.post('/api/affiliateservice/lt_v1', requestBody, { headers });

            if (response.status === 200 && response.data && Array.isArray(response.data.results)) {
                return response.data.results[0];
            } else {
                console.error('Failed to fetch hotel details in KRW');
                return null;
            }
        } catch (err) {
            console.error('Failed to fetch hotel details in KRW:', err);
            return null;
        }
    };

    useEffect(() => {
        if (cityId) {
            fetchHotels();
        }
    }, [fetchHotels, page, cityId, sortBy]);

    const lastHotelElementRef = useCallback(node => {
        if (loading || hotels.length >= 30) return; // 로딩 중이거나 호텔이 30개 이상이면 스크롤 감지를 멈춤
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                setPage(prevPage => prevPage + 1);
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hotels.length]);

    const handleHotelSelect = (hotel) => {
        setSelectedHotel(hotel); // 선택된 호텔을 설정
    };

    const handleNext = () => {
        if (selectedHotel) {
            navigate('/summary', {
                state: {
                    selectedTheme,
                    selectedActivity,
                    selectedRegion,
                    startDate,
                    endDate,
                    numAdults,
                    numChildren,
                    selectedRegionId,
                    docId,
                    hotelImageURL: selectedHotel.imageURL, // 호텔 이미지 URL 추가
                    hotelName: selectedHotel.hotelName, // 호텔 이름 추가
                    hotelLandingURL: selectedHotel.landingURL,
                    hotelId: selectedHotel.hotelId,
                },
            });
        }
    };

    const extractKoreanOrEnglish = (text) => {
        const korean = text ? text.match(/[가-힣\s]+/g)?.join(' ').trim() : '';
        const english = text ? text.match(/[a-zA-Z\s]+/g)?.join(' ').trim() : '';
        return korean || english || text; // 한글이 있으면 한글, 없으면 영어, 둘 다 없으면 원래 텍스트 반환
    };

    const handleSortChange = (e) => {
        setHotels([]); // 호텔 목록을 초기화
        setPage(1); // 페이지를 초기화
        setSortBy(e.target.value); // 정렬 기준을 업데이트
    };

    return (
        <>
            <Header pageTitle="숙소 정하기" />
            <div className={styles.searchAccommodationsWrapper}>
                <h1 className={styles.title}>{selectedRegion}의 숙소에요</h1>
                <div className={styles.infoBox}>
                    <p><strong>여행일자:</strong> {startDate ? formatDate(new Date(startDate)) : ''} - {endDate ? formatDate(new Date(endDate)) : ''}</p>
                    <p><strong>인원수:</strong> 어른 {numAdults}명{numChildren > 0 && `, 어린이 ${numChildren}명`}</p>
                </div>

                {/* 정렬 버튼 추가 */}
                <div className={styles.sortButtons}>
                    <label htmlFor="sortOptions">정렬 기준: </label>
                    <select id="sortOptions" value={sortBy} onChange={handleSortChange}>
                        <option value="Recommended">추천순</option>
                        <option value="PriceDesc">가격 높은 순</option>
                        <option value="PriceAsc">가격 낮은 순</option>
                        <option value="StarRatingDesc">별점 높은 순</option>
                        <option value="StarRatingAsc">별점 낮은 순</option>
                        <option value="AllGuestsReviewScore">전체 이용자 평점순</option>
                        <option value="BusinessTravellerReviewScore">출장객 평점순</option>
                        <option value="CouplesReviewScore">커플 평점순</option>
                        <option value="SoloTravllersReviewScore">혼자 여행자 평점순</option>
                        <option value="FamiliesWithYoungReviewScore">어린 자녀 동반 가족 평점순</option>
                        <option value="FamiliesWithTeenReviewScore">청소년 자녀 동반 가족 평점순</option>
                        <option value="GroupsReviewScore">그룹 여행자 평점순</option>
                    </select>
                </div>

                {error && <p>{error}</p>}
                <div className={styles.resultsContainer}>
                    {hotels.map((hotel, index) => {
                        const isSelected = selectedHotel && selectedHotel.hotelId === hotel.hotelId;
                        const hotelCard = (
                            <div
                                className={`${styles.hotelCard} ${isSelected ? styles.selectedHotelCard : ''}`}
                                key={`${hotel.hotelId}-${index}`}
                                onClick={() => handleHotelSelect(hotel)}
                                style={{ backgroundColor: isSelected ? '#f0f8ff' : 'white' }}
                            >
                                <img src={hotel.imageURL} alt={hotel.hotelName}
                                     style={{width: '100%', height: 'auto'}}/>
                                <h3 className={styles.hotelName}>{extractKoreanOrEnglish(hotel.hotelName)}</h3>
                                <div className={styles.hotelDetails}>
                                    <p>평점: {hotel.reviewScore}</p>
                                    <p>가격: {hotel.dailyRate}원</p> {/* 원화 가격 표시 */}
                                </div>
                                <button className={styles.reserveButton} onClick={() => window.open(hotel.landingURL, '_blank')}>
                                    예약하기
                                </button>
                            </div>
                        );

                        if (hotels.length === index + 1) {
                            return (
                                <div ref={lastHotelElementRef} key={`${hotel.hotelId}-${index}`}>
                                    {hotelCard}
                                </div>
                            );
                        } else {
                            return hotelCard;
                        }
                    })}
                </div>
                <button className={styles.button} onClick={handleNext} disabled={!selectedHotel}>결정 끝! 최종 일정을 확인해주세요</button>
            </div>
        </>
    );
};

export default SearchAccommodations;
