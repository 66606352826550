import React from 'react';
import styled from 'styled-components';
import Header from '../../components/common/Header';
import ReviewSection from '../../components/Review/ReviewSection';
import LatestReviews from '../../components/Review/LatestReviews';
import PopularByRegion from '../../components/Review/PopularByRegion';
import PopularByActivity from '../../components/Review/PopularByActivity';
import BestReviews from '../../components/home/Review';
import Footer from '../../components/common/Footer';
import CheckUserData from '../../components/auth/CheckUserData'; // CheckUserData 컴포넌트 추가

// 최상위 컨테이너 스타일
const Container = styled.div`
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
`;

const Title = styled.h2`
    padding-left: 1rem;
    color: black;
    text-align: left;
    margin-bottom: 1rem;
`;

const ReviewPage = () => (
  <>
    <Header pageTitle="리뷰"/>
    <CheckUserData> {/* CheckUserData로 페이지 감싸기 */}
      <Container>
        <ReviewSection />

        <Title>최신 리뷰</Title>
        <LatestReviews />

        <Title>베스트 리뷰</Title>
        <BestReviews />

        <Title>지역별 인기 리뷰</Title>
        <PopularByRegion />

        <Title>챌린지별 인기 리뷰</Title>
        <PopularByActivity />
      </Container>
    </CheckUserData>
    <Footer />
  </>
);

export default ReviewPage;
