import React from 'react';
import styled from 'styled-components';
import CreateIcon from '@mui/icons-material/Create';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';

const ReviewSectionWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 8px;
    margin-bottom: 20px;
`;

const ReviewText = styled.div`
    flex: 1;
    padding-right: 20px;

    h3 {
        margin: 0;
        font-size: 1.5rem;
        color: #333;
    }

    p {
        margin: 5px 0;
        font-size: 1rem;
        color: #555;
    }
`;

const WriteButton = styled.button`
    display: flex;
    align-items: center;
    background-color: #0096ff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    font-size: 1rem;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        background-color: #007bbf;
    }
`;

const ReviewSection = () => {
    const { user } = useAuth();
    const navigate = useNavigate();

    const handleWriteReview = () => {
        if (user) {
            navigate('/add-review');
        } else {
            alert('로그인이 필요합니다.');
            navigate('/login');
        }
    };

    return (
        <ReviewSectionWrapper>
            <ReviewText>
                <h3>여행후기</h3>
                <p>여러분의 여행 이야기를 들려주세요</p>
            </ReviewText>
            <WriteButton onClick={handleWriteReview}>
                <span><CreateIcon/></span>
            </WriteButton>
        </ReviewSectionWrapper>
    );
};

export default ReviewSection;
