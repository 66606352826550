import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../context/AuthContext';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';  // Firestore 초기화

const CheckUserData = ({ children }) => {
  const { user } = useAuth(); // 현재 로그인된 사용자 정보 가져오기
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkUserData = async () => {
      if (user) {
        const q = query(collection(db, 'users'), where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          // Firestore에 사용자 정보가 없으면 GoogleSignUp으로 리다이렉트
          navigate('/google-signup');
        } else {
          setLoading(false); // 사용자 정보가 있으면 로딩 상태 해제
        }
      } else {
        // 로그인이 되어 있지 않으면 로그인 페이지로 리다이렉트
        navigate('/login');
      }
    };

    checkUserData();
  }, [user, navigate]);

  if (loading) {
    return <div>Loading...</div>; // 로딩 상태일 때 로딩 메시지
  }

  return children; // Firestore에 사용자 정보가 있는 경우에만 자식 컴포넌트 렌더링
};

export default CheckUserData;
