import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper/modules';

const ReviewsContainer = styled.div`
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    max-width: 1200px;
`;

const Title = styled.h2`
    color: #0096ff;
    text-align: left;
    margin-bottom: 1rem;
`;

const ReviewCard = styled.div`
    background-color: #61788a;
    text-align: left;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 200px; /* Increased width */
    height: 150px; /* Reduced height */
    box-sizing: border-box;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
`;

const ProfileSection = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 0.5rem;
`;

const ProfileImage = styled.img`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin-right: 0.5rem;
`;

const UserName = styled.strong`
    color: white;
    font-size: 0.9rem;
`;

const ReviewContent = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 100%;
`;

const ReviewTitle = styled.h3`
    color: white;
    font-size: 1rem;
    margin: 0;
`;

const ReviewText = styled.p`
    color: white;
    font-size: 0.9rem;
    margin: 0;
    flex-grow: 1;
`;

const DateText = styled.div`
    color: white;
    font-size: 0.8rem;
    margin-top: 0.2rem;
`;

const StyledSwiper = styled(Swiper)`
    .swiper-wrapper {
        display: flex;
        align-items: center;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .swiper-slide {
        display: flex;
        justify-content: center;
        box-sizing: border-box;
        width: 200px; /* Increased width */
        height: 100px; /* Reduced height */  
        margin-bottom: 70px;
    }
    
    .swiper-pagination {
        position: relative;
    }

    .swiper-pagination-bullet {
        background: #0096ff;
    }
`;

const Review = () => {
    const reviews = [
        {
            id: 1,
            profile: 'https://via.placeholder.com/50',
            name: 'John Doe',
            title: 'Amazing Trip!',
            content: 'Had a fantastic time visiting the mountains.',
            date: '2024. 06. 15',
        },
        {
            id: 2,
            profile: 'https://via.placeholder.com/50',
            name: 'Jane Smith',
            title: 'Beautiful Beach',
            content: 'The beach was stunning and very clean.',
            date: '2024. 06. 10',
        },
        {
            id: 3,
            profile: 'https://via.placeholder.com/50',
            name: 'Tom Brown',
            title: 'Great City Tour',
            content: 'Loved the city tour, very informative and fun.',
            date: '2024. 06. 05',
        },
        {
            id: 4,
            profile: 'https://via.placeholder.com/50',
            name: 'Alice Blue',
            title: 'Wonderful Adventure',
            content: 'It was an unforgettable experience, so much fun!',
            date: '2024. 06. 20',
        },
        {
            id: 5,
            profile: 'https://via.placeholder.com/50',
            name: 'Bob Green',
            title: 'Amazing Food Tour',
            content: 'The food tour was delicious and enlightening!',
            date: '2024. 06. 12',
        },
    ];

    return (
        <ReviewsContainer>
            <Title>이런 리뷰 참고해보세요</Title>
            <StyledSwiper
                slidesPerView={'auto'}
                spaceBetween={15}
                centeredSlides={false}
                pagination={{
                    clickable: true,
                }}
                modules={[Pagination]}
            >
                {reviews.map((review) => (
                    <SwiperSlide key={review.id}>
                        <ReviewCard>
                            <ProfileSection>
                                <ProfileImage src={review.profile} alt={review.name} />
                                <UserName>{review.name}</UserName>
                            </ProfileSection>
                            <ReviewContent>
                                <ReviewTitle>{review.title}</ReviewTitle>
                                <ReviewText>{review.content}</ReviewText>
                                <DateText>{review.date}</DateText>
                            </ReviewContent>
                        </ReviewCard>
                    </SwiperSlide>
                ))}
            </StyledSwiper>
        </ReviewsContainer>
    );
};

export default Review;
