import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, Autoplay } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import styled from 'styled-components';
import { getStorage, ref, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../../context/AuthContext';
import { useNavigate } from 'react-router-dom';

const BannerWrapper = styled.div`
  width: 100%;
  aspect-ratio: 16/9;
  max-height: 400px;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    display: flex;
    max-height: 400px;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
`;

const Slide = styled.div`
  width: 100%;
  height: 100%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 30px;
`;

const SlideText = styled.h1`
  margin: 0;
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-bottom: 10px;
`;

const SlideDescription = styled.p`
  margin: 0;
  font-size: 1rem;
  color: white;
  text-align: center;
  margin-bottom: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 20px;
`;

const Button = styled.button`
  background-color: #0096ff;
  color: white;
  border: 2px;
  border-radius: 20px;
  padding: 10px 30px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    opacity: 0.8;
  }
`;

const Banner = () => {
  const { user } = useAuth();
  const [images, setImages] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchImages = async () => {
      const storageRef = getStorage();
      const paths = ['Home/Banner/1.jpg', 'Home/Banner/2.jpg', 'Home/Banner/3.jpg'];
      const urls = await Promise.all(
          paths.map(async (path) => {
            const imageRef = ref(storageRef, path);
            return await getDownloadURL(imageRef);
          })
      );
      setImages(urls);
    };
    fetchImages();
  }, []);

  const handleLoginClick = () => navigate('/login');
  const handleSignupClick = () => navigate('/random-tour');

  return (
      <BannerWrapper>
        <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{ delay: 3000 }}
        >
          {images.map((url, index) => (
              <SwiperSlide key={index}>
                <Slide backgroundImage={url}>
                  <SlideText>Explore the World</SlideText>
                  <SlideDescription>Find your next adventure across the globe.</SlideDescription>
                  {!user && index === 0 && (
                      <ButtonContainer>
                        <Button onClick={handleLoginClick}>로그인</Button>
                        <Button onClick={handleSignupClick}>시작하기</Button>
                      </ButtonContainer>
                  )}
                </Slide>
              </SwiperSlide>
          ))}
        </Swiper>
      </BannerWrapper>
  );
};

export default Banner;
