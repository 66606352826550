import React, { useState, useEffect, useRef } from 'react';
import {
  RecaptchaVerifier,
  signInWithPhoneNumber,
  PhoneAuthProvider,
  linkWithCredential,
    signInWithCredential,
} from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { auth, db } from '../../../firebase';

const PhoneInput = ({
  onPhoneChange,
  phoneCheckMessage,
  setPhoneCheckMessage,
  setPhoneCredential,
  context,
}) => {
  const [phonePart1, setPhonePart1] = useState('');
  const [phonePart2, setPhonePart2] = useState('');
  const [phonePart3, setPhonePart3] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [smsError, setSmsError] = useState('');
  const [smsSuccess, setSmsSuccess] = useState(false);

  // Use useRef to store recaptchaVerifier
  const recaptchaVerifierRef = useRef(null);

  const initializeRecaptcha = () => {
    if (!recaptchaVerifierRef.current) {
      recaptchaVerifierRef.current = new RecaptchaVerifier(
          auth,
        'recaptcha-container',
        {
          size: 'invisible',
          callback: () => {
            console.log('reCAPTCHA solved.');
          },
          'expired-callback': () => {
            console.log('reCAPTCHA expired. Resetting...');
            recaptchaVerifierRef.current.reset();
          },
        },
      );
    }
  };

  useEffect(() => {
    initializeRecaptcha(); // Initialize on mount
  }, []);

  useEffect(() => {
    const fullPhone = `${phonePart1}-${phonePart2}-${phonePart3}`;
    onPhoneChange(fullPhone);
  }, [phonePart1, phonePart2, phonePart3, onPhoneChange]);

  const handlePhoneChange = () => {
    const fullPhone = `${phonePart1}-${phonePart2}-${phonePart3}`;
    onPhoneChange(fullPhone);
  };

  const handleCheckAndSendCode = async () => {
    const fullPhone = `+82${phonePart1}${phonePart2}${phonePart3}`; // International phone format
    if (phonePart1 && phonePart2 && phonePart3) {
      try {
        // Remove the re-initialization of reCAPTCHA here
        // No need to check or initialize recaptchaVerifierRef.current again

        const isDuplicate = await checkPhoneDuplicate(fullPhone);
        if (isDuplicate) {
          setPhoneCheckMessage('이미 등록된 전화번호입니다.');
          setSmsError('이미 등록된 전화번호입니다.');
          return;
        }

        setPhoneCheckMessage('사용 가능한 전화번호입니다.');
        const appVerifier = recaptchaVerifierRef.current;
        const result = await signInWithPhoneNumber(auth, fullPhone, appVerifier); // Start phone number authentication
        setConfirmationResult(result);
        setIsCodeSent(true);
        setSmsError('');

        // Optionally reset the reCAPTCHA if you want to allow re-use
        // recaptchaVerifierRef.current.clear();
      } catch (error) {
        setSmsError('SMS 전송에 실패했습니다. 다시 시도해주세요.');
        console.error('SMS 전송 실패:', error);

        // Reset the reCAPTCHA in case of an error
        recaptchaVerifierRef.current.clear();
        initializeRecaptcha();
      }
    } else {
      setSmsError('휴대전화 번호를 모두 입력해주세요.');
    }
  };

  const checkPhoneDuplicate = async (phone) => {
    const q = query(collection(db, 'users'), where('phone', '==', phone));
    const querySnapshot = await getDocs(q);
    return !querySnapshot.empty;
  };

  const handleVerifyCode = async () => {
  if (verificationCode && confirmationResult) {
    try {
      const phoneCredential = PhoneAuthProvider.credential(
        confirmationResult.verificationId,
        verificationCode
      );

      if (context === 'GoogleSignUp') {
        const user = auth.currentUser;
        if (user) {
          try {
            await linkWithCredential(user, phoneCredential);
            setSmsError('인증이 완료되었습니다.');
            setSmsSuccess(true);
            setPhoneCheckMessage('사용 가능한 전화번호입니다.');
          } catch (error) {
            console.error('Google 계정에 전화번호 연결 실패:', error);
            setSmsError(
              '전화번호를 Google 계정에 연결하는 중 오류가 발생했습니다.'
            );
            setSmsSuccess(false);
          }
        } else {
          setSmsError('사용자 인증에 문제가 발생했습니다. 다시 로그인해주세요.');
          setSmsSuccess(false);
        }
      } else if (context === 'SignUpPage') {
        try {
          // Verify the phone credential by signing in
          const signInResult = await signInWithCredential(auth, phoneCredential);

          // Phone credential is valid; proceed
          setPhoneCredential(phoneCredential);
          setSmsError('인증이 완료되었습니다.');
          setSmsSuccess(true);
          setPhoneCheckMessage('사용 가능한 전화번호입니다.');

          // Sign out the temporary user
          await auth.signOut();
        } catch (error) {
          console.error('전화번호 인증 실패:', error);
          setSmsError('잘못된 인증 코드입니다. 다시 시도해주세요.');
          setSmsSuccess(false);

          // If signed in, sign out
          if (auth.currentUser) {
            await auth.signOut();
          }
        }
      }
    } catch (error) {
      setSmsError('잘못된 인증 코드입니다. 다시 시도해주세요.');
      setSmsSuccess(false);
      console.error('인증 실패:', error);
    }
  } else {
    setSmsError('인증 코드를 입력해주세요.');
    setSmsSuccess(false);
  }
};


  return (
    <div className="signup-inputWrapper">
      <label htmlFor="phone" className="signup-label">
        휴대전화 번호
      </label>
      <div className="signup-phoneWrapper">
        <input
          type="tel"
          id="phonePart1"
          placeholder="010"
          value={phonePart1}
          onChange={(e) => {
            setPhonePart1(e.target.value);
            handlePhoneChange();
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={3}
        />
        -
        <input
          type="tel"
          id="phonePart2"
          placeholder="1234"
          value={phonePart2}
          onChange={(e) => {
            setPhonePart2(e.target.value);
            handlePhoneChange();
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={4}
        />
        -
        <input
          type="tel"
          id="phonePart3"
          placeholder="5678"
          value={phonePart3}
          onChange={(e) => {
            setPhonePart3(e.target.value);
            handlePhoneChange();
          }}
          required
          className="signup-input signup-phoneInput"
          maxLength={4}
        />
        <button
          type="button"
          className="signup-checkButton"
          onClick={handleCheckAndSendCode}
        >
          SMS 인증
        </button>
      </div>

      {phoneCheckMessage && (
        <p className="signup-checkMessage" style={{ color: 'green' }}>
          {phoneCheckMessage}
        </p>
      )}

      {/* Ensure this div is always rendered */}
      <div id="recaptcha-container"></div>

      {isCodeSent && (
        <div
          className="signup-codeWrapper"
          style={{
            display: 'flex',
            alignItems: 'center',
            marginTop: '1rem',
          }}
        >
          <input
            type="text"
            id="verificationCode"
            placeholder="인증코드를 입력하세요"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
            className="signup-input"
            style={{ marginRight: '10px' }}
          />
          <button
            type="button"
            className="signup-checkButton"
            onClick={handleVerifyCode}
          >
            인증코드 확인
          </button>
        </div>
      )}

      {smsError && (
        <p className="signup-checkMessage" style={{ color: 'red' }}>
          {smsError}
        </p>
      )}
    </div>
  );
};

export default PhoneInput;
