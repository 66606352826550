import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth, db } from '../../firebase'; // Firestore db 추가
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { collection, query, where, getDocs } from 'firebase/firestore'; // Firestore 관련 함수 추가
import { FaGoogle, FaApple } from 'react-icons/fa';
import Header from '../common/Header';
import './LoginPage.css';

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  // 로그인 시 이전 페이지로 돌아가기 위한 경로 설정
  const from = location.state?.from?.pathname || '/';

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        const userRef = collection(db, 'users');
        const q = query(userRef, where('uid', '==', user.uid));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.empty) {
          // Firestore에 사용자가 없으면 GoogleSignUp으로 이동
          navigate('/google-signup');
        } else {
          // 기존 사용자가 있으면 이전 페이지로 리다이렉트
          navigate(from, { replace: true });
        }
      }
    });
    return () => unsubscribe();
  }, [navigate, from]);

  const handleEmailChange = (e) => setEmail(e.target.value);
  const handlePasswordChange = (e) => setPassword(e.target.value);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');

    try {
      await signInWithEmailAndPassword(auth, email, password);
      // 로그인 성공 시 Firestore 확인 후 처리됨
    } catch (error) {
      setError('로그인에 실패했습니다. 이메일과 비밀번호를 확인하세요.');
    }
  };

  const handleGoogleLogin = async () => {
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      // 로그인 성공 시 Firestore 확인 후 처리됨
    } catch (error) {
      setError('Google 로그인에 실패했습니다.');
      console.error(error);
    }
  };

  const handleSignUp = () => {
    navigate('/signup');
  };

  const handleForgotID = () => {
    navigate('/forgot-id');
  };

  const handleForgotPassword = () => {
    navigate('/forgot-password');
  };

  const handleAppleLogin = () => {
    alert('애플 로그인 기능은 아직 구현되지 않았습니다.');
  };

  return (
      <>
        <Header />
        <div className="login-pageWrapper">
          <div className="login-formWrapper">
            <h2 className="login-title">Welcome back!</h2>
            <form onSubmit={handleLogin}>
              <div className="login-inputWrapper">
                <label htmlFor="email" className="login-label">이메일</label>
                <input
                    type="email"
                    id="email"
                    placeholder="Enter your email"
                    value={email}
                    onChange={handleEmailChange}
                    required
                    className="login-input"
                />
              </div>
              <div className="login-inputWrapper">
                <label htmlFor="password" className="login-label">비밀번호</label>
                <input
                    type="password"
                    id="password"
                    placeholder="Enter your Password"
                    value={password}
                    onChange={handlePasswordChange}
                    required
                    className="login-input"
                />
              </div>
              {error && <p className="login-error">{error}</p>}
              <div className="login-buttonWrapper">
                <button type="button" className="login-button login-buttonSecondary" onClick={handleSignUp}>회원가입</button>
                <button type="submit" className="login-button login-buttonPrimary">로그인</button>
              </div>
            </form>
            <div className="login-orDivider">or</div>
            <div className="login-socialButtonWrapper">
              <button className="login-socialButton login-googleButton" onClick={handleGoogleLogin}>
                <FaGoogle className="login-icon" /> Continue with Google
              </button>
              <button className="login-socialButton login-appleButton" onClick={handleAppleLogin}>
                <FaApple className="login-icon" /> Continue with Apple
              </button>
            </div>
            <div className="login-helpWrapper">
              <button type="button" className="login-helpButton" onClick={handleForgotID}>아이디 찾기</button>
              <button type="button" className="login-helpButton" onClick={handleForgotPassword}>비밀번호 찾기</button>
            </div>
          </div>
        </div>
      </>
  );
};

export default LoginPage;
