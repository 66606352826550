import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../../firebase';  // Firestore 설정

// 스타일링
const RegionContainer = styled.div`
  padding: 0;
  background-color: white;
  border-radius: 10px;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden;
`;

const RegionCard = styled.div`
  background-color: white;
  border-radius: 10px;
  text-align: center;
  width: 100px;
  height: 150px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f0f0f0;
  }
`;

const RegionImage = styled.img`
  width: 75px;
  height: 60px;
  object-fit: contain;
  border-radius: 5px;
  margin-bottom: 10px;
`;

const RegionName = styled.h3`
  margin: 0;
  color: ${(props) => (props.selected ? '#0096ff' : '#333')};
  font-size: 1rem;
  font-weight: ${(props) => (props.selected ? 'bold' : 'normal')};
`;

const StyledSwiper = styled(Swiper)`
  .swiper-wrapper {
    display: flex;
    align-items: center;
  }

  .swiper-slide {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: auto;
    padding: 0 15px;
  }

  .swiper-pagination-bullet {
    background: #0096ff;
  }
`;

const RegionSelect = ({ onSelectRegion }) => {
  const [regions, setRegions] = useState([]);
  const [regionLogos, setRegionLogos] = useState({});
  const [selectedRegion, setSelectedRegion] = useState(null);  // 선택된 지역 ID 저장

  // Firebase에서 지역 목록 및 로고 데이터 가져오기
  const fetchRegionsFromFirebase = async () => {
    const regionsCollection = collection(db, 'regions');
    const regionSnapshot = await getDocs(regionsCollection);
    const regionList = regionSnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    return regionList;
  };

  const fetchRegionLogos = async (regions) => {
    const logos = {};
    for (const region of regions) {
      logos[region.id] = region.logo || 'https://via.placeholder.com/50'; // 로고가 없으면 기본 이미지 사용
    }
    setRegionLogos(logos);
  };

  useEffect(() => {
    const loadRegions = async () => {
      const regionList = await fetchRegionsFromFirebase();
      setRegions(regionList);
      await fetchRegionLogos(regionList);
    };

    loadRegions();
  }, []);

  // 지역 선택 시 호출되는 함수
  const handleRegionSelect = (region) => {
    setSelectedRegion(region.id);  // 선택된 지역 ID 저장
    onSelectRegion(region);  // 선택한 지역을 상위 컴포넌트로 전달
  };

  return (
    <RegionContainer>
      <StyledSwiper
        slidesPerView={'auto'}
        spaceBetween={15}
        pagination={{ clickable: true }}
      >
        {regions.map((region) => (
          <SwiperSlide key={region.id} onClick={() => handleRegionSelect(region)}>
            <RegionCard>
              <RegionImage src={regionLogos[region.id]} alt={region.name} />
              <RegionName selected={selectedRegion === region.id}>{region.name}</RegionName>
            </RegionCard>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </RegionContainer>
  );
};

export default RegionSelect;
