import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth, db } from '../../firebase';
import { updateProfile, linkWithCredential, PhoneAuthProvider } from 'firebase/auth';
import { collection, addDoc } from 'firebase/firestore';
import Header from '../common/Header';
import PhoneInput from './SignUp/PhoneInput';
import BirthdayInput from './SignUp/BirthdayInput';
import NameInput from './SignUp/NameInput'; // NameInput 컴포넌트 추가
import './GoogleSignUp.css';

const GoogleSignUp = () => {
  const [name, setName] = useState('');
  const [phone, setPhone] = useState(''); // 입력된 전화번호 저장
  const [birthday, setBirthday] = useState('');
  const [phoneCheckMessage, setPhoneCheckMessage] = useState('');
  const [error, setError] = useState('');
  const [nameCheckMessage, setNameCheckMessage] = useState('');
  const [phoneCredential, setPhoneCredential] = useState(null); // 인증된 phoneCredential 저장
  const navigate = useNavigate();

  const handleSignUp = async (e) => {
    e.preventDefault();
    setError('');

    if (!phoneCheckMessage.includes('사용 가능한')) {
      setError('휴대전화 번호의 중복 체크를 확인해주세요.');
      return;
    }

    if (!nameCheckMessage.includes('사용 가능한')) {
      setError('이름 중복 확인을 해주세요.');
      return;
    }

    try {
      const user = auth.currentUser;

      if (user) {
        // 이미 휴대전화 제공자가 연결되었는지 확인
        const linkedProviders = user.providerData.map((provider) => provider.providerId);

        // 휴대전화 제공자가 연결되지 않은 경우에만 linkWithCredential 실행
        if (!linkedProviders.includes(PhoneAuthProvider.PROVIDER_ID) && phoneCredential) {
          // 휴대전화 인증 정보를 Google 계정에 연결
          await linkWithCredential(user, phoneCredential);
        }

        // 사용자 프로필 업데이트
        await updateProfile(user, { displayName: name });

        // Firestore에 사용자 정보 저장
        await addDoc(collection(db, 'users'), {
          uid: user.uid,
          email: user.email,
          name,
          birthday,
          phone, // 전화번호 저장
        });

        // 회원가입 완료 후 리다이렉트
        navigate('/signup-complete');
      } else {
        setError('사용자 인증에 실패했습니다. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error(error);
      setError('회원가입 중 오류가 발생했습니다.');
    }
  };

  return (
    <>
      <Header />
      <div className="signup-pageWrapper">
        <div className="signup-formWrapper">
          <h2 className="signup-title">추가 정보 입력</h2>
          <body3 className="sub-title">추가 정보 입력 후 정상 사용 가능합니다</body3>
          <form onSubmit={handleSignUp}>
            {/* NameInput 컴포넌트 사용 */}
            <NameInput
              name={name}
              setName={setName}
              setNameCheckMessage={setNameCheckMessage}
            />
            {/* BirthdayInput 컴포넌트 재사용 */}
            <BirthdayInput onBirthdayChange={setBirthday} />
            {/* PhoneInput 컴포넌트 재사용 - 휴대전화 인증 */}
            <PhoneInput
              onPhoneChange={setPhone}
              phoneCheckMessage={phoneCheckMessage}
              setPhoneCheckMessage={setPhoneCheckMessage}
              setPhoneCredential={setPhoneCredential} // 인증된 phoneCredential을 저장
              context="GoogleSignUp"
            />
            {error && <p className="signup-error">{error}</p>}
            <div className="signup-buttonWrapper">
              <button type="submit" className="signup-button signup-buttonPrimary">회원가입 완료</button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default GoogleSignUp;
