import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import Grid from '@mui/material/Grid';

const BirthdayInput = ({ onBirthdayChange }) => {
  const [year, setYear] = useState(null); // 빈 문자열 대신 null로 초기화
  const [month, setMonth] = useState(null); // 빈 문자열 대신 null로 초기화
  const [day, setDay] = useState(null); // 빈 문자열 대신 null로 초기화

  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const years = [];
    for (let i = currentYear; i >= 1900; i--) {
      years.push(i);
    }
    return years;
  };

  const generateMonthOptions = () => {
    return Array.from({ length: 12 }, (v, k) => k + 1);
  };

  const generateDayOptions = (year, month) => {
    if (!year || !month) return [];
    const daysInMonth = new Date(year, month, 0).getDate();
    return Array.from({ length: daysInMonth }, (v, k) => k + 1);
  };

  useEffect(() => {
    const birthday = `${year || ''}-${month || ''}-${day || ''}`;
    onBirthdayChange(birthday);
  }, [year, month, day, onBirthdayChange]);

  const handleYearChange = (event, newValue) => {
    setYear(newValue);
    if (month && day > new Date(newValue, month, 0).getDate()) {
      // 선택된 일이 새로운 년도와 월에 유효하지 않으면 일 초기화
      setDay(null);
    }
  };

  const handleMonthChange = (event, newValue) => {
    setMonth(newValue);
    if (year && day > new Date(year, newValue, 0).getDate()) {
      // 선택된 일이 새로운 년도와 월에 유효하지 않으면 일 초기화
      setDay(null);
    }
  };

  return (
    <div className="signup-inputWrapper">
      <label className="signup-label">생일</label>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <Autocomplete
            options={generateYearOptions()}
            getOptionLabel={(option) => option.toString()}
            value={year}
            onChange={handleYearChange}
            renderInput={(params) => <TextField {...params} label="년도" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            options={generateMonthOptions()}
            getOptionLabel={(option) => option.toString()}
            value={month}
            onChange={handleMonthChange}
            renderInput={(params) => <TextField {...params} label="월" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
          />
        </Grid>

        <Grid item xs={4}>
          <Autocomplete
            options={generateDayOptions(year, month)}
            getOptionLabel={(option) => option.toString()}
            value={day}
            onChange={(event, newValue) => setDay(newValue)}
            renderInput={(params) => <TextField {...params} label="일" />}
            fullWidth
            isOptionEqualToValue={(option, value) => option === value || value === null}
            disabled={!year || !month} // 년도와 월이 선택되지 않으면 비활성화
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default BirthdayInput;
