import React from 'react';
import Header from '../components/common/Header';
import Banner from '../components/home/Banner';
import PopularTours from '../components/home/PopularTours';
import Review from '../components/home/Review';
import Announcement from '../components/home/Announcement';
import Footer from '../components/common/Footer';
import styled from 'styled-components';

const Container = styled.div`
  max-width: 1200px; /* 최대 너비 설정 */
  margin: 0 auto; /* 중앙 정렬 */
  padding: 1rem 0.5rem; /* 좌우 패딩 */
  overflow: hidden;

  @media (max-width: 1200px) {
    padding: 0; /* 1200px 이하에서는 패딩 제거 */
  }
`;

const Section = styled.div`
  margin-bottom: 0; /* 컴포넌트 사이에 여백 추가 */
    
    &:last-child{
        margin-bottom: 0;
    }
`;

const Title = styled.h2`
    padding-left: 1rem;
    color: #0096ff;
    text-align: left;
    margin-bottom: 1rem;
`;

const Home = () => (
    <>
        <Header isHome={true} />
      <Container>
        <Section>
          <Banner />
        </Section>
        <Section>
          <PopularTours />
        </Section>
        <Section>
          <Title>베스트 리뷰</Title>
          <Review />
        </Section>
        <Section>
          <Announcement />
        </Section>
        <Section>
          <Footer />
        </Section>
      </Container>
    </>
);

export default Home;