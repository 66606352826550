import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import Header from '../common/Header';

// Content 스타일
const Content = styled.div`
  display: flex;
  width: 100%; 
  height: 100%;
  min-height: 80vh;
`;

// Section 스타일
const Section = styled.div`
  flex: 1;
  position: relative;
  cursor: pointer;
  background-size: cover;
  background-position: center;
  transition: transform 0.3s ease;

  &:hover {
    transform: scale(1.05);
  }

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3); /* 어두운 오버레이 */
  }

  & h2 {
    text-align: center;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-size: 2rem;
    z-index: 1;
  }
`;

// LeftSection 스타일
const LeftSection = styled(Section)`
  background-image: url('/assets/random1.jpg');
`;

// RightSection 스타일
const RightSection = styled(Section)`
  background-image: url('/assets/random2.jpg');
`;

// RandomTour 컴포넌트
const RandomTour = () => {
  const navigate = useNavigate();
  const location = useLocation();

  // 이전 페이지에서 전달된 상태를 추출
  const { startDate, endDate, numAdults, numChildren } = location.state || {};

  return (
    <>
      <Header pageTitle={"선택"}/>
      <Content>
        <LeftSection onClick={() => navigate('/challenge', { state: { startDate, endDate, numAdults, numChildren } })}>
          <h2>챌린지<br/>고르기</h2>
        </LeftSection>
        <RightSection onClick={() => navigate('/region', { state: { startDate, endDate, numAdults, numChildren } })}>
          <h2>지역<br/>고르기</h2>
        </RightSection>
      </Content>
    </>
  );
};

export default RandomTour;
