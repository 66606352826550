import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import RegionSelect from '../Review/RegionSelect'; // RegionSelect 컴포넌트 가져오기
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
import Pagination from '@mui/material/Pagination';

const TouristSpotContainer = styled.div`
  padding: 1rem;
  background-color: white;
  border-radius: 10px;
  width: calc(100% - 2rem);  /* 부모 요소의 크기에 맞춰짐 */
  max-width: 1200px;
  margin: 0 auto;
  overflow: hidden; /* 가로 스크롤 제거 */
`;

const TouristSpotGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 1rem;
`;

const LoadingText = styled.p`
  font-size: 1.2rem;
  color: grey;
  text-align: center;
`;

const PaginationContainer = styled.div`
  width: 100%;  /* 부모 요소의 크기에 맞춰서 확장 */
  display: flex;
  justify-content: center; /* 가운데 정렬 */
  margin-top: 1rem;
`;

// 타이틀과 설명을 위한 스타일
const TitleSection = styled.div`
  text-align: center;
  padding: 0.5rem 0;
`;

const MainTitle = styled.h1`
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 0.5rem;
`;

const SubTitle = styled.p`
  font-size: 1.2rem;
  color: #666;
`;

const Toursight = () => {
  const [selectedRegion, setSelectedRegion] = useState(null); // 선택된 지역 저장 상태
  const [touristSpots, setTouristSpots] = useState([]); // 관광지 데이터를 저장할 상태
  const [loading, setLoading] = useState(false); // API 요청 상태
  const [currentPage, setCurrentPage] = useState(1); // 현재 페이지 상태
  const [totalPages, setTotalPages] = useState(0); // 전체 페이지 수

  const serviceKey = process.env.REACT_APP_SERVICE_KEY; // 환경 변수에서 서비스 키 가져오기

  // 특정 지역 선택 시 관광지 정보를 가져오는 함수
  const fetchTouristSpots = async (region, page) => {
    setLoading(true); // 로딩 시작
    try {
      const response = await fetch(
        `https://apis.data.go.kr/B551011/KorService1/areaBasedSyncList1?serviceKey=${serviceKey}&numOfRows=10&pageNo=${page}&MobileOS=ETC&MobileApp=AppTest&_type=json&listYN=Y&arrange=A&areaCode=32&sigunguCode=${region.toursiguncd}&contentTypeId=12`
      );
      const data = await response.json();
      setTouristSpots(data.response.body.items.item); // 관광지 데이터를 상태에 저장
      setTotalPages(Math.ceil(data.response.body.totalCount / 10)); // 전체 페이지 수 계산
    } catch (error) {
      console.error('Failed to fetch tourist spots:', error);
    } finally {
      setLoading(false); // 로딩 끝
    }
  };

  // 지역 선택 시 호출되는 함수
  const handleRegionSelect = (region) => {
    setSelectedRegion(region); // 선택된 지역을 상태에 저장
    setCurrentPage(1); // 페이지를 1로 초기화
    fetchTouristSpots(region, 1); // 첫 번째 페이지의 관광지 데이터를 불러옴
  };

  // 페이지 변경 시 호출되는 함수
  const handlePageChange = (event, value) => {
    setCurrentPage(value); // 선택된 페이지를 상태에 저장
    if (selectedRegion) {
      fetchTouristSpots(selectedRegion, value); // 선택된 페이지의 관광지 데이터를 불러옴
    }
  };

  return (
    <div className="content">
      {/* 타이틀 섹션 */}
      <TitleSection>
        <MainTitle>지역 관광지 소개</MainTitle>
        <SubTitle>강원 지역 관광지 소개</SubTitle> {/* 지역에 따라 변경 가능 */}
      </TitleSection>

      {/* RegionSelect 컴포넌트 사용 */}
      <RegionSelect onSelectRegion={handleRegionSelect} /> {/* 지역 선택 */}

      {/* 선택된 지역 및 관광지 목록 표시 */}
      {selectedRegion && (
        <div>
          {loading ? (
            <LoadingText>관광지 정보를 불러오는 중...</LoadingText>
          ) : (
            <TouristSpotContainer>
              <TouristSpotGrid>
                {touristSpots.map((spot) => (
                  <Card key={spot.contentid} sx={{ width: '100%' }}>
                    <CardActionArea component={Link} to={`/toursight/${spot.contentid}`}>
                      <CardMedia
                        component="img"
                        height="140"
                        image={spot.firstimage || 'https://via.placeholder.com/300'}
                        alt={spot.title}
                      />
                      <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                          {spot.title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          {spot.addr1 || '주소 정보 없음'}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                ))}
              </TouristSpotGrid>

              {/* 페이지네이션 */}
              <PaginationContainer>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                  variant="outlined"
                  shape="rounded"
                  siblingCount={1}  // 현재 페이지 주변에 몇 개의 페이지를 표시할지 설정
                  boundaryCount={1}  // 처음과 끝에 표시할 페이지 수 설정
                  sx={{ width: '100%', justifyContent: 'center', display: 'flex' }}  // 가로 크기 설정
                />
              </PaginationContainer>
            </TouristSpotContainer>
          )}
        </div>
      )}
    </div>
  );
};

export default Toursight;
