import React from 'react';
import styled from 'styled-components';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination'; // Only import pagination CSS
import { Pagination, Autoplay } from 'swiper/modules'; // Import Autoplay module for automatic sliding

const AnnouncementsContainer = styled.div`
  padding-left: 0;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  max-width: 1200px;
  margin: 0;
`;

const Title = styled.h2`
  padding-left: 1rem;
  color: black;
  text-align: left;
  margin-top: 0;
`;

const StyledSwiper = styled(Swiper)`
  height: 50px; /* Swiper 높이 설정 */
  margin-top: 1rem;
  display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const AnnouncementTitle = styled.h3`
  color: #333;
  font-size: 1rem;
  margin-left: 1rem; /* 왼쪽 패딩 */
  margin-top: 0;
  margin-bottom: 0;
`;

const Announcement = () => {
  const announcements = [
    { id: 1, title: '클로즈 베타 오픈' },
    { id: 2, title: '오픈 베타는 10/01' },
    { id: 3, title: '이상준은 머머리인가요?' },
    { id: 4, title: '넵!' },
    { id: 5, title: 'ㅜㅜ' },
  ];

  return (
    <AnnouncementsContainer>
      <Title>공지사항</Title>
      <StyledSwiper
        direction={'vertical'} // 세로 슬라이드 설정
        spaceBetween={20}
        slidesPerView={1}
        loop={true}
        autoplay={{
          delay: 3000, // 3초 간격으로 자동 슬라이드
          disableOnInteraction: false, // 사용자가 스와이프해도 자동 슬라이드 유지
        }}
        modules={[Pagination, Autoplay]} // Autoplay와 Pagination 모듈 사용
      >
        {announcements.map((announcement) => (
          <SwiperSlide key={announcement.id}>
            <AnnouncementTitle>- {announcement.title}</AnnouncementTitle>
          </SwiperSlide>
        ))}
      </StyledSwiper>
    </AnnouncementsContainer>
  );
};

export default Announcement;
